import { chain, cloneDeep, get } from 'lodash';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { ShipmentHelper as PageShipmentHelpers } from '../../helpers';
import { ShipmentHelpers as BaseShipmentHelpers } from '../../../../helpers';
import * as STRINGS from '../../../../constants/strings';
import { REFERENCE_NAME } from '../../constants';

export const getInitialValues = values => {
  const clonedValues = cloneDeep(values);

  return chain(clonedValues)
    .set(
      SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY,
      get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY) || ''
    )
    .set(
      SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY,
      get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY) || ''
    )
    .set(
      SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY,
      get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY) || ''
    )
    .thru(result =>
      BaseShipmentHelpers.isInvoiceAvailable(values)
        ? chain(result)
            .set(SHIPMENT_FIELDS.CURRENCY.KEY, STRINGS.GBP)
            .set(
              SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY,
              PageShipmentHelpers.getDefaultIsBusiness(values, {
                businessPath: SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY,
                organisationPath: SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY,
              })
            )
            .set(
              SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
              PageShipmentHelpers.getDefaultIsBusiness(values, {
                businessPath: SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
                organisationPath: SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY,
              })
            )
            .value()
        : result
    )
    .value();
};

export const getInvoiceAvailableFields = values => {
  const isInvoiceAvailable = BaseShipmentHelpers.isInvoiceAvailable(values);
  const isBusiness = get(
    values,
    SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  );
  const isUkimsAvailable = BaseShipmentHelpers.isUkimsAvailable(values);

  //TODO: add ioss field
  return {
    isInvoiceAvailable,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY]: isInvoiceAvailable,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY]:
      BaseShipmentHelpers.isAtRiskAvailable(values),
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY]: isBusiness,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY]: isBusiness,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY]: !isBusiness,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY]: isUkimsAvailable,
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY]: isInvoiceAvailable,
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY]: isInvoiceAvailable,
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY]:
      BaseShipmentHelpers.isFdaNumberAvailable(values),
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY]: isUkimsAvailable,
  };
};

export const getAllowedFields = (values, references, currentSession) => ({
  ...getInvoiceAvailableFields(values),
  [SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY]: get(
    values,
    SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY,
    false
  ),
  [SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY]: get(
    values,
    SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  ),
  saveForNextTime:
    !get(values, SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY) &&
    currentSession?.uid,
  saveForNextTimeReceiver:
    !get(values, SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY) &&
    currentSession?.uid,
  collectionPickupPoint: references[REFERENCE_NAME.COLLECTION_PICKUP],
  destinationPickupPoint: references[REFERENCE_NAME.DELIVERY_PICKUP],
});

export const getActivePickupPoints = references => ({
  collectionPickupPoint: references[REFERENCE_NAME.COLLECTION_PICKUP],
  destinationPickupPoint: references[REFERENCE_NAME.DELIVERY_PICKUP],
});

export const getInvoiceRequiredFields = values => {
  if (!BaseShipmentHelpers.isInvoiceAvailable(values)) {
    return {};
  }

  const isBusiness = get(
    values,
    SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  );

  return {
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY]: true,
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY]:
      BaseShipmentHelpers.isFlagRequired(
        values,
        SHIPMENT_FIELDS.NETWORK_FDA_REQUIRED.KEY
      ),
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY]:
      BaseShipmentHelpers.isUkimsRequired(values),
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY]: true,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY]:
      !isBusiness &&
      BaseShipmentHelpers.isFlagRequired(
        values,
        SHIPMENT_FIELDS.NETWORK_PID_NUMBER.KEY
      ),
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY]:
      isBusiness &&
      BaseShipmentHelpers.isFlagRequired(
        values,
        SHIPMENT_FIELDS.NETWORK_VAT_NUMBER.KEY
      ),
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY]:
      BaseShipmentHelpers.isEoriRequired(values),
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY]:
      BaseShipmentHelpers.isUkimsRequired(values),
  };
};

export const getMandatoryFields = values => ({
  ...getInvoiceRequiredFields(values),
  [SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY]: true,
  [SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL.KEY]: true,
  [SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY]: true,
  [SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY]: true,
  [SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY]: true,
  [SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY]: true,
});

export const getRequiredKeys = requiredFields =>
  Object.entries(requiredFields).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(key);
    }

    return acc;
  }, []);
