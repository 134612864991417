import { useState } from 'react';
import { Box, Typography } from '@mui/material';

import GalleryWidget from '../../GalleryWidget';
import { ORDER_DETAILS } from '../../../constants/strings';
import { getImageUrl } from '../../../utils/images';
import GalleryIconButton from '../../GalleryIconButton';
import notFoundImg from '../../../assets/images/image-not-found.png';
import mapNotAvailable from '../../../assets/images/mapNotAvailable.jpeg';
import { generateMockedMapStaticImageUrl } from '../../../utils/map';

const TrackingSection = ({ mapSrc, images }) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [imageError, setImageError] = useState(false);

  const mockedMapImage = generateMockedMapStaticImageUrl({
    withMarkers: true,
    width: 600,
    height: 340,
  });

  const mapToShow = mapSrc || mockedMapImage;

  return mapToShow || images?.length > 0 ? (
    <>
      <Typography
        variant='body2'
        color='textSecondary'
        sx={{
          fontFamily: 'Roboto',
          marginY: 1,
        }}
      >
        Tracking
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
          lineHeight: 0,
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              md: images.length > 0 ? '50%' : '100%',
            },
          }}
        >
          <Box
            component='img'
            src={imageError ? mapNotAvailable : mapToShow}
            width='100%'
            sx={{
              borderRadius: 1,
              filter: imageError ? 'blur(5px)' : 'none',
            }}
            onError={() => {
              setImageError(true);
            }}
          />
        </Box>
        {images.length > 0 && (
          <>
            <GalleryWidget
              title={ORDER_DETAILS.GALLERY_TITLE}
              images={images}
              isOpen={isGalleryOpen}
              onClose={() => setIsGalleryOpen(false)}
            />
            <Box
              sx={{
                display: 'flex',
                width: { xs: '100%', md: '50%' },
                position: 'relative',
              }}
            >
              <Box
                component='img'
                width='100%'
                src={getImageUrl(images[0])}
                onError={e => (e.target.src = notFoundImg)}
                sx={{ borderRadius: 1, cursor: 'pointer' }}
                onClick={() => setIsGalleryOpen(true)}
              />
              <GalleryIconButton
                imagesNumber={images.length}
                onClick={() => setIsGalleryOpen(true)}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  ) : null;
};

export default TrackingSection;
