import { useCallback } from 'react';
import { createSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Form } from 'react-final-form';
import { TextField as TextFieldRFF } from 'mui-rff';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  List,
  Typography,
  useTheme,
} from '@mui/material';
import { flow } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

import { FIELDS } from '../../../constants/forms';
import {
  FOUND_REFERENCE_NUMBER,
  TRACK,
  TRACK_YOUR_PARCEL,
} from '../../../constants/strings';
import { trackParcelSchema, validateWithJoi } from '../../../utils/validators';
import { replaceLetters } from '../../../utils/strings';
import { normalizeParcelNumber } from '../../../utils/parcel';

const Sidebar = ({ parcelNumber }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleFormSubmit = useCallback(
    formValues => {
      const { parcelNumber } = formValues;

      if (parcelNumber.length > 0) {
        navigate(
          {
            pathname: location.pathname,
            search: createSearchParams([
              [FIELDS.PARCEL_NUMBER.KEY, parcelNumber],
            ]).toString(),
          },
          {
            replace: true,
          }
        );
      }
    },
    [navigate]
  );

  return (
    <Box>
      <Drawer
        anchor='left'
        variant='permanent'
        sx={{
          height: '100%',
        }}
        PaperProps={{
          sx: {
            width: { xs: '100%', md: 300, lg: 330 },
            borderRight: {
              xs: 'none',
              md: `1px solid ${theme.palette.primary.borderGray}`,
            },
            position: 'static',
            px: { xs: 2, md: 4 },
            height: {
              xs: 'auto',
              md: '100%',
            },
          },
        }}
      >
        <List
          sx={{
            display: { xs: 'flex' },
            flexDirection: 'column',
            height: {
              md: '100%',
            },
            mt: 1,
          }}
        >
          <Typography variant='h3' sx={{ pt: 2, pb: 2 }}>
            {TRACK_YOUR_PARCEL}
          </Typography>
          <Form
            onSubmit={handleFormSubmit}
            validate={values => validateWithJoi(values, trackParcelSchema)}
            initialValues={{ parcelNumber: parcelNumber }}
            render={({ handleSubmit, form, values, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <TextFieldRFF
                    name={FIELDS.PARCEL_NUMBER.KEY}
                    label={FIELDS.PARCEL_NUMBER.LABEL}
                    placeholder={FIELDS.PARCEL_NUMBER.PLACEHOLDER}
                    fullWidth
                    InputProps={{
                      inputmode: 'numeric',
                      pattern: '[0-9]*',
                      endAdornment: !!values[FIELDS.PARCEL_NUMBER.KEY]
                        ?.length && (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={() => {
                              navigate('/track');
                              form.reset();
                            }}
                            edge='end'
                            aria-label='copy link'
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fieldProps={{
                      parse: flow([replaceLetters, normalizeParcelNumber]),
                    }}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        event.target.blur();
                        handleSubmit();
                      }
                    }}
                  />
                  <Button
                    variant='contained'
                    type='submit'
                    size='large'
                    disabled={invalid}
                  >
                    {TRACK}
                  </Button>
                  <Divider />
                  <Typography variant='body1' sx={{ mb: { xs: 4, md: 0 } }}>
                    {FOUND_REFERENCE_NUMBER}
                  </Typography>
                </Box>
              </form>
            )}
          />
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
