import { cloneDeep, get, omit, set } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Checkboxes, Radios, TextField } from 'mui-rff';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { OnChange } from 'react-final-form-listeners';

import * as STRINGS from '../../../../constants/strings';
import { ORDER_ADDRESS_DETAILS } from '../../../../constants/analytics';
import { SummaryDetails } from '../../../../components/SummaryDetails/index';
import InformationCollecting from '../../../../components/InformationCollecting/index';
import AddressWidget from '../../../../components/AddressWidget';
import DropDown from '../../../../components/DropDown/';
import WizardForm from '../../../../features/Wizard/Form';
import PickupMapImage from '../../../../components/MapBox/components/PickupMapImage';
import { useReferencesLoader } from '../../hooks';
import { useOverlay } from '../../../../features/Overlay';
import { DEFAULT_BOOLEAN_KEY_VALUE, USER_TYPES } from '../../constants';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { BasketActions, BasketSelectors } from '../../../../redux/basket';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import addressBooksSlice from '../../../../redux/addressBooksSlice';
import { ShipmentValidator } from '../../validators';
import { getMaxWeightAndNumberOfParcels } from '../ParcelDetails/selectors';
import { countriesSelectors } from '../../../../redux/countriesSlice';
import { useAuth } from '../../../../features/Auth';
import * as OrderSelectors from '../../selectors';
import {
  AddressBookUtil,
  AddressUtil,
  Formatters,
  Normalizers,
  StringUtil,
  useAnalytics,
  Validators,
} from '../../../../utils';
import { minParcelValue } from '../../validators/parcel';
import { ShipmentHelper as PageShipmentHelpers } from '../../helpers';
import Debugger from '../../../../components/Debugger';
import { InvoiceHelpers } from '../../../../helpers';
import * as AddressDetailsSelectors from './selectors';

// Form field wrapper styled for flex layout
const FieldWrapper = ({ children, sx }) => (
  <Box
    sx={{
      width: { xs: '100%', md: '50%' },
      p: 1,
      pb: 2,
      ...sx,
    }}
  >
    {children}
  </Box>
);

const AddressDetails = ({
  nextStep,
  previousStep,
  values,
  setValues,
  references,
}) => {
  const { setInterfaceId, Trackable, trackAction } = useAnalytics();
  const theme = useTheme();
  const snackbar = useCustomSnackbar();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const companyNameRef = useRef(null);
  const receiverCompanyNameRef = useRef(null);
  const overlay = useOverlay();
  const { currentSession } = useAuth();
  const referencesLoader = useReferencesLoader();
  const reduxInitialValues = useSelector(
    AddressDetailsSelectors.getInitialFormValues
  );
  const availableFields = useSelector(state =>
    AddressDetailsSelectors.getAvailableFields(state, currentSession)
  );
  const { collectionPickupPoint, destinationPickupPoint } = useSelector(
    AddressDetailsSelectors.getPickupPoints
  );
  const { requiredFields, requiredKeys } = useSelector(
    AddressDetailsSelectors.getRequiredData
  );
  const countries = useSelector(countriesSelectors.getCountries);
  const currencies = InvoiceHelpers.getCurrenciesKeyValue(
    references.currencies
  );
  const exportReasons = InvoiceHelpers.filterExportReasons(
    references.exportReasons,
    values
  );
  const price = useSelector(OrderSelectors.getTotalAmount);
  const basketItemId = searchParams.get('basketItemId');
  const basketItem = useSelector(state =>
    BasketSelectors.getBasketItemById(state, basketItemId)
  );

  // TODO: Think of better way to handle dinamic validation between steps
  const context = useSelector(getMaxWeightAndNumberOfParcels);

  const initialValues = useMemo(() => reduxInitialValues, []);
  const customsValueLabel = useMemo(
    () =>
      SHIPMENT_FIELDS.CUSTOMS_VALUE.LABEL(
        PageShipmentHelpers.getCurrencySymbol(
          get(values, SHIPMENT_FIELDS.CURRENCY.KEY)
        )
      ),
    [get(values, SHIPMENT_FIELDS.CURRENCY.KEY)]
  );

  const companyName = get(values, SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY);
  const receiverCompanyName = get(
    values,
    SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY
  );

  useEffect(() => {
    setInterfaceId(ORDER_ADDRESS_DETAILS.INTERFACE_ID);
    overlay.show();
    (async () =>
      Promise.all([
        referencesLoader.loadDropOffCollection(),
        referencesLoader.loadDeliveryPickup(),
        referencesLoader.loadCollectionAddress(),
        referencesLoader.loadDeliveryAddress(),
        referencesLoader.loadInvoiceData(),
      ]))().finally(() => {
      overlay.hide();
    });
  }, []);

  useEffect(() => {
    if (receiverCompanyNameRef.current && receiverCompanyName?.length > 15) {
      receiverCompanyNameRef.current.focus();
      receiverCompanyNameRef.current.blur();
    }
    if (companyNameRef.current && companyName?.length > 15) {
      companyNameRef.current.focus();
      companyNameRef.current.blur();
    }
  }, [receiverCompanyNameRef.current, companyNameRef.current]);

  const handleFieldChange = useCallback((selectorValue, form, field, value) => {
    const newValue = selectorValue ? value : '';

    form.change(field, newValue);
  }, []);

  const handleNext = event => {
    trackAction(event);
  };

  const handleBack = useCallback(
    event => {
      trackAction(event);
      const newValues = omit(values, [
        'saveForNextTime',
        'saveForNextTimeReceiver',
      ]);

      setValues(newValues);

      previousStep();
    },
    [previousStep, setValues, values]
  );

  const saveAddressBook = useCallback(details => {
    const addressBookData = AddressBookUtil.normalizeAddressBookDataForPayload({
      data: {
        address: details.address,
        email:
          details.contactDetails.email || details.notificationDetails?.email,
        contactName: details.contactDetails.contactName,
        phoneNumber:
          details.contactDetails.telephone ||
          details.notificationDetails?.mobile,
      },
    });
    return dispatch(
      addressBooksSlice.actions.createAddressBook(addressBookData)
    ).unwrap();
  }, []);

  const onSubmit = useCallback(
    async ({ saveForNextTime, saveForNextTimeReceiver, ...formData }) => {
      try {
        overlay.show();
        const values = cloneDeep(formData);

        if (saveForNextTime) {
          const { addressBookId } = await saveAddressBook(
            get(values, SHIPMENT_FIELDS.COLLECTION_DETAILS.KEY)
          );
          set(
            values,
            SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY,
            addressBookId
          );
        }
        if (saveForNextTimeReceiver) {
          const { addressBookId } = await saveAddressBook(
            get(values, SHIPMENT_FIELDS.DELIVERY_DETAILS.KEY)
          );
          set(
            values,
            SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY,
            addressBookId
          );
        }

        const updatedBasketItem = {
          basketItemId: basketItemId,
          shipment: values,
        };

        await dispatch(
          BasketActions.updateBasketItem(updatedBasketItem)
        ).unwrap();

        nextStep(values);
      } catch (e) {
        snackbar.showError({
          message: STRINGS.FAILED_TO_SAVE_BASKET,
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch, nextStep, overlay, searchParams]
  );

  const addressDetailsValidationSchema = useCallback(
    (deliveryCountryValue, collectionCountryValue) =>
      Validators.requireKeysSchema(
        ShipmentValidator.shipmentSchema(
          deliveryCountryValue,
          collectionCountryValue
        ),
        requiredKeys
      ),
    [requiredKeys]
  );

  const validate = useCallback(
    values => {
      const deliveryCountryValue = AddressUtil.getCountryValue({
        countries,
        countryCode: get(values, SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY),
      });

      const collectionCountryValue = AddressUtil.getCountryValue({
        countries,
        countryCode: get(values, SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY),
      });

      return Validators.validateWithJoi(
        values,
        addressDetailsValidationSchema(
          deliveryCountryValue,
          collectionCountryValue
        ),
        {
          allowUnknown: true,
          context,
        }
      );
    },
    [countries, addressDetailsValidationSchema, context]
  );

  return (
    <Trackable loadId={ORDER_ADDRESS_DETAILS.LOAD}>
      <WizardForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ handleSubmit, submitting, invalid, values, form, errors }) => (
          <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item xs={12} md={8}>
              <form id='addressForm' onSubmit={handleSubmit}>
                {availableFields.isInvoiceAvailable && (
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant='h3'>
                      {STRINGS.ADDRESS_DETAILS.CUSTOMS_DETAILS}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        my: 1,
                      }}
                    >
                      <FieldWrapper>
                        <DropDown
                          name={SHIPMENT_FIELDS.CURRENCY.KEY}
                          label={SHIPMENT_FIELDS.CURRENCY.LABEL}
                          options={currencies}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <TextField
                          name={SHIPMENT_FIELDS.CUSTOMS_VALUE.KEY}
                          label={customsValueLabel}
                          fullWidth
                          fieldProps={{
                            parse: value =>
                              Normalizers.formatAndConstrainDecimal({
                                value,
                                maxIntegerLength: 8, // TODO: confirm maxIntegerLength
                                maxDecimalLength: 2,
                                minValue: minParcelValue,
                              }),
                            format: StringUtil.formatDecimalString,
                            formatOnBlur: true,
                          }}
                        />
                      </FieldWrapper>
                      {availableFields[
                        SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY
                      ] && (
                        <>
                          <FieldWrapper>
                            <DropDown
                              name={
                                SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY
                              }
                              label={
                                SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS
                                  .LABEL
                              }
                              options={USER_TYPES}
                              required={
                                requiredFields[
                                  SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS
                                    .KEY
                                ]
                              }
                              fieldProps={{
                                parse: value =>
                                  Normalizers.booleanOrNullValueNormalize(
                                    value
                                  ),
                              }}
                            />
                          </FieldWrapper>
                          <OnChange
                            name={
                              SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY
                            }
                          >
                            {value =>
                              handleFieldChange(
                                value,
                                form,
                                SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY,
                                get(
                                  basketItem.shipment,
                                  SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY
                                )
                              )
                            }
                          </OnChange>
                          <FieldWrapper>
                            <DropDown
                              name={
                                SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY
                              }
                              label={
                                SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS
                                  .LABEL
                              }
                              options={USER_TYPES}
                              fieldProps={{
                                parse: value =>
                                  Normalizers.booleanOrNullValueNormalize(
                                    value
                                  ),
                              }}
                            />
                            <OnChange
                              name={
                                SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY
                              }
                            >
                              {value =>
                                handleFieldChange(
                                  value,
                                  form,
                                  SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY,
                                  get(
                                    basketItem.shipment,
                                    SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY
                                  )
                                )
                              }
                            </OnChange>
                          </FieldWrapper>
                        </>
                      )}
                      <FieldWrapper>
                        <DropDown
                          name={SHIPMENT_FIELDS.INVOICE_EXPORT_REASON.KEY}
                          label={SHIPMENT_FIELDS.INVOICE_EXPORT_REASON.LABEL}
                          options={exportReasons}
                        />
                      </FieldWrapper>
                      {availableFields[
                        SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY
                      ] && (
                        <FieldWrapper
                          sx={{
                            width: { xs: '100%' },
                          }}
                        >
                          <FormControl
                            component='fieldset'
                            sx={{
                              p: 2,
                              backgroundColor: '#E5F6FD',
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={7}>
                                <Typography
                                  sx={{
                                    fontFamily: 'Roboto',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px',
                                    textAlign: 'justify',
                                  }}
                                >
                                  {STRINGS.AT_RISK_TITLE}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: 'Pluto Sans DPD',
                                    fontSize: '12px',
                                    fontWeight: 370,
                                    lineHeight: '14.82px',
                                    letterSpacing: '0.17px',
                                    textAlign: 'justify',
                                    mt: 1,
                                  }}
                                >
                                  {STRINGS.AT_RISK_TEXT}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <Radios
                                  radioGroupProps={{
                                    row: true,
                                  }}
                                  name={
                                    SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY
                                  }
                                  data={DEFAULT_BOOLEAN_KEY_VALUE}
                                  fieldProps={{
                                    parse: value =>
                                      Normalizers.booleanOrNullValueNormalize(
                                        value
                                      ),
                                    format: value =>
                                      Normalizers.booleanOrNullValueFormat(
                                        value
                                      ),
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </FormControl>
                        </FieldWrapper>
                      )}
                    </Box>
                  </Paper>
                )}
                <Paper
                  sx={{
                    p: 2,
                    mb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant='h3'>
                    {STRINGS.ADDRESS_DETAILS.DETAILS_TITLE}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      my: 1,
                    }}
                  >
                    {availableFields[
                      SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY
                    ] && (
                      <FieldWrapper sx={{ width: '100%' }}>
                        <TextField
                          label={STRINGS.ADDRESS_DETAILS.COMPANY_NAME}
                          value={get(
                            values,
                            SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY
                          )}
                          fullWidth
                          name={SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY}
                          inputRef={companyNameRef}
                          fieldProps={{
                            formatOnBlur: true,
                            format: Formatters.trim,
                          }}
                        />
                      </FieldWrapper>
                    )}
                    <FieldWrapper>
                      <TextField
                        label={STRINGS.ADDRESS_DETAILS.CONTACT_NAME}
                        fullWidth
                        name={SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY}
                        required={
                          requiredFields[
                            SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY
                          ]
                        }
                        fieldProps={{
                          formatOnBlur: true,
                          format: Formatters.trim,
                        }}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <TextField
                        label={STRINGS.ADDRESS_DETAILS.EMAIL}
                        fullWidth
                        name={SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL.KEY}
                        required={
                          requiredFields[
                            SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL.KEY
                          ]
                        }
                        fieldProps={{
                          formatOnBlur: true,
                          format: Formatters.trim,
                        }}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <TextField
                        label={STRINGS.ADDRESS_DETAILS.PHONE}
                        fullWidth
                        name={SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY}
                        required={
                          requiredFields[
                            SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY
                          ]
                        }
                      />
                    </FieldWrapper>
                    {availableFields[
                      SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY
                    ] && (
                      <FieldWrapper>
                        <TextField
                          name={
                            SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY
                          }
                          label={
                            SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.LABEL
                          }
                          required={
                            requiredFields[
                              SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY
                            ]
                          }
                          fullWidth
                          fieldProps={{
                            formatOnBlur: true,
                            format: Formatters.trim,
                          }}
                        />
                      </FieldWrapper>
                    )}
                    {availableFields[
                      SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY
                    ] && (
                      <FieldWrapper>
                        <TextField
                          name={SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY}
                          label={
                            SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.LABEL
                          }
                          fullWidth
                          fieldProps={{
                            formatOnBlur: true,
                            format: Formatters.trim,
                          }}
                        />
                      </FieldWrapper>
                    )}
                    {availableFields[
                      SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY
                    ] && (
                      <FieldWrapper>
                        <TextField
                          name={
                            SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY
                          }
                          label={
                            SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.LABEL
                          }
                          fullWidth
                          fieldProps={{
                            formatOnBlur: true,
                            format: Formatters.trim,
                          }}
                        />
                      </FieldWrapper>
                    )}
                    {availableFields[
                      SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY
                    ] && (
                      <FieldWrapper>
                        <TextField
                          name={SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY}
                          label={
                            SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.LABEL
                          }
                          required={
                            requiredFields[
                              SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY
                            ]
                          }
                          fullWidth
                          fieldProps={{
                            formatOnBlur: true,
                            format: Formatters.trim,
                          }}
                        />
                      </FieldWrapper>
                    )}
                    <FieldWrapper>
                      <TextField
                        name={SHIPMENT_FIELDS.INVOICE_EXPORTER_IOSS_NUMBER.KEY}
                        label={
                          SHIPMENT_FIELDS.INVOICE_EXPORTER_IOSS_NUMBER.LABEL
                        }
                        fullWidth
                        fieldProps={{
                          formatOnBlur: true,
                          format: Formatters.trim,
                        }}
                      />
                    </FieldWrapper>
                    <AddressWidget
                      address={get(
                        values,
                        SHIPMENT_FIELDS.COLLECTION_ADDRESS.KEY
                      )}
                      onEditClick={handleBack}
                    />
                    {availableFields.saveForNextTime && (
                      <FormControl sx={{ p: 1, pl: 2 }}>
                        <Checkboxes
                          name='saveForNextTime'
                          data={{
                            label: STRINGS.ADDRESS_DETAILS.SAVE_FOR_NEXT_TIME,
                            value: 1,
                          }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Paper>
                {availableFields.collectionPickupPoint && (
                  <PickupMapImage
                    title={STRINGS.YOUR_PICKUP_POINT}
                    pickupPoint={collectionPickupPoint}
                    buttonLabel={STRINGS.CHANGE_YOUR_PICKUP_POINT}
                    buttonOnClick={handleBack}
                  />
                )}
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography variant='h3'>
                    {STRINGS.ADDRESS_DETAILS.RECEIVER_DETAILS_TITLE}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      my: 1,
                    }}
                  >
                    {availableFields[
                      SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY
                    ] && (
                      <FieldWrapper sx={{ width: '100%' }}>
                        <TextField
                          label={STRINGS.ADDRESS_DETAILS.COMPANY_NAME}
                          fullWidth
                          name={SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY}
                          value={get(
                            values,
                            SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY
                          )}
                          inputRef={receiverCompanyNameRef}
                          fieldProps={{
                            formatOnBlur: true,
                            format: Formatters.trim,
                          }}
                        />
                      </FieldWrapper>
                    )}
                    <FieldWrapper>
                      <TextField
                        label={STRINGS.ADDRESS_DETAILS.RECEIVER_CONTACT_NAME}
                        fullWidth
                        name={SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY}
                        required={
                          requiredFields[
                            SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY
                          ]
                        }
                        fieldProps={{
                          formatOnBlur: true,
                          format: Formatters.trim,
                        }}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <TextField
                        label={STRINGS.ADDRESS_DETAILS.RECEIVER_EMAIL}
                        fullWidth
                        name={SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY}
                        required={
                          requiredFields[
                            SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY
                          ]
                        }
                        fieldProps={{
                          formatOnBlur: true,
                          format: Formatters.trim,
                        }}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <TextField
                        label={STRINGS.ADDRESS_DETAILS.RECEIVER_PHONE}
                        fullWidth
                        name={SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY}
                        required={
                          requiredFields[
                            SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY
                          ]
                        }
                      />
                    </FieldWrapper>
                    {availableFields[
                      SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY
                    ] && (
                      <FieldWrapper>
                        <TextField
                          name={
                            SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY
                          }
                          label={
                            SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.LABEL
                          }
                          required={
                            requiredFields[
                              SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY
                            ]
                          }
                          fullWidth
                          fieldProps={{
                            formatOnBlur: true,
                            format: Formatters.trim,
                          }}
                        />
                      </FieldWrapper>
                    )}
                    {availableFields[
                      SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY
                    ] && (
                      <FieldWrapper>
                        <TextField
                          name={SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY}
                          label={
                            SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.LABEL
                          }
                          required={
                            requiredFields[
                              SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY
                            ]
                          }
                          fullWidth
                          fieldProps={{
                            formatOnBlur: true,
                            format: Formatters.trim,
                          }}
                        />
                      </FieldWrapper>
                    )}
                    {availableFields[
                      SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY
                    ] && (
                      <FieldWrapper>
                        <TextField
                          name={
                            SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY
                          }
                          label={
                            SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.LABEL
                          }
                          fullWidth
                          fieldProps={{
                            formatOnBlur: true,
                            format: Formatters.trim,
                          }}
                        />
                      </FieldWrapper>
                    )}
                    {availableFields[
                      SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY
                    ] && (
                      <FieldWrapper>
                        <TextField
                          name={SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY}
                          label={
                            SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.LABEL
                          }
                          required={
                            requiredFields[
                              SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY
                            ]
                          }
                          fullWidth
                          fieldProps={{
                            formatOnBlur: true,
                            format: Formatters.trim,
                          }}
                        />
                      </FieldWrapper>
                    )}
                    <AddressWidget
                      address={get(
                        values,
                        SHIPMENT_FIELDS.DESTINATION_ADDRESS.KEY
                      )}
                      onEditClick={handleBack}
                    />
                    {availableFields.saveForNextTimeReceiver && (
                      <FormControl sx={{ p: 1, pl: 2 }}>
                        <Checkboxes
                          name='saveForNextTimeReceiver'
                          data={{
                            label: STRINGS.ADDRESS_DETAILS.SAVE_FOR_NEXT_TIME,
                            value: 1,
                          }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Paper>
                {availableFields.destinationPickupPoint && (
                  <PickupMapImage
                    title={STRINGS.RECEIVER_PICKUP_POINT}
                    pickupPoint={destinationPickupPoint}
                    buttonLabel={STRINGS.CHANGE_YOUR_PICKUP_POINT}
                    buttonOnClick={handleBack}
                  />
                )}
              </form>
              <Debugger>
                <pre>{JSON.stringify(errors, null, 2)}</pre>
              </Debugger>
              <InformationCollecting sx={{ pl: 2, pr: 2 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ position: 'sticky', top: theme.spacing(2) }}>
                <SummaryDetails
                  price={price}
                  basketItemsWithPrice={[{ shipment: values, price }]}
                />
                <Box
                  sx={{
                    gap: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    pl: { xs: 2, md: 0 },
                    pr: { xs: 2, md: 0 },
                  }}
                >
                  <Button
                    actionid={ORDER_ADDRESS_DETAILS.CLICK_BACK}
                    variant='outlined'
                    onClick={handleBack}
                    sx={{ width: '100%' }}
                  >
                    {STRINGS.BACK}
                  </Button>
                  <Button
                    actionid={ORDER_ADDRESS_DETAILS.CLICK_NEXT}
                    variant='contained'
                    onClick={handleNext}
                    sx={{ width: '100%' }}
                    form='addressForm'
                    type='submit'
                    disabled={submitting || invalid}
                  >
                    {STRINGS.NEXT_STEP}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </WizardForm>
    </Trackable>
  );
};

export default AddressDetails;
