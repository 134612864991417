import { Alert, Typography, Box } from '@mui/material';

import {
  INCOMPLETE_ORDER,
  ORDER_MISSING_INFO,
} from '../../../constants/strings';

const IncompleteOrderAlert = () => (
  <Box sx={{ p: 2, pb: 0 }}>
    <Alert icon={false} severity='warning' sx={{ color: '#5F2120' }}>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 'bold',
          mb: 1,
        }}
      >
        {INCOMPLETE_ORDER}
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
        }}
      >
        {ORDER_MISSING_INFO} {/* TODO: add missing fields info  */}
      </Typography>
    </Alert>
  </Box>
);

export default IncompleteOrderAlert;
