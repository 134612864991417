import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import FacePensiveIcon from '../../../components/icons/FacePensiveIcon';
import { HOME, SIGN_IN_WITH_EMAIL, TRACK } from '../../../constants/routes';
import {
  BACK_TO_HOME,
  BACK_TO_LOGIN,
  BACK_TO_ORDERS,
  IF_YOU_RECEIVED_THIS_LINK,
  IT_LOOKS_LIKE_WE_ENCOUNTERED,
  IT_LOOKS_LIKE_YOUR_TRYING,
  LOGIN,
  SORRY_YOU_DONT_HAVE_ACCESS,
  TRACK_PARCEL,
  WE_HAVE_A_LITTLE_PROBLEM,
} from '../constants.js';

export const useErrorBoundaryConfigurations = () => {
  const navigate = useNavigate();
  return {
    default: () => {},
    weHaveALittleProblem: (error = '') => ({
      title: WE_HAVE_A_LITTLE_PROBLEM,
      descriptionArray: [error],
      mainIcon: <FacePensiveIcon />,
    }),
    loadingCrashWithSingIn: () => ({
      title: WE_HAVE_A_LITTLE_PROBLEM,
      descriptionArray: [IT_LOOKS_LIKE_WE_ENCOUNTERED],
      mainIcon: <FacePensiveIcon />,
      actionContent: () => (
        <Button
          variant={'contained'}
          onClick={() => navigate(SIGN_IN_WITH_EMAIL)}
        >
          {BACK_TO_LOGIN}
        </Button>
      ),
    }),
    loadingCrash: (error = '') => ({
      title: WE_HAVE_A_LITTLE_PROBLEM,
      descriptionArray: [error],
      mainIcon: <FacePensiveIcon />,
      actionContent: () => (
        <Button variant={'contained'} onClick={() => navigate(HOME)}>
          {BACK_TO_HOME}
        </Button>
      ),
    }),
    dontHaveAccess: () => ({
      title: SORRY_YOU_DONT_HAVE_ACCESS,
      descriptionArray: [IT_LOOKS_LIKE_YOUR_TRYING, IF_YOU_RECEIVED_THIS_LINK],
      mainIcon: <FacePensiveIcon />,
      actionContent: () => (
        <Box sx={{ display: 'flex' }}>
          <Button
            variant='contained'
            sx={{ marginRight: '12px' }}
            onClick={() => navigate(SIGN_IN_WITH_EMAIL)}
          >
            {LOGIN}
          </Button>
          <Button variant='outlined' onClick={() => navigate(TRACK)}>
            {TRACK_PARCEL}
          </Button>
        </Box>
      ),
    }),
    orderError: (error = '') => ({
      title: WE_HAVE_A_LITTLE_PROBLEM,
      descriptionArray: [error],
      mainIcon: <FacePensiveIcon />,
      actionContent: () => (
        <Button variant='contained' onClick={() => navigate('/profile/orders')}>
          {BACK_TO_ORDERS}
        </Button>
      ),
    }),
  };
};
