import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { SHARE_TRACKING_INFO } from '../../../constants/strings';
import OrderDetails from '../../../components/OrderDetails';
import EmptySearchResults from '../../../components/EmptySearchResults';
import Loader from '../../../features/Loader';
import useParcelSearch from '../../../hooks/useParcelSearch';
import {
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../../../features/ErrorBoundary';
import ShareModal from '../../../components/ShareModal/ShareModal';

const TrackSection = ({ parcel, parcelNumber, isSmallScreen, onBackClick }) => {
  const { fetchParcel, isParcelFound } = useParcelSearch();
  const { weHaveALittleProblem } = useErrorBoundaryConfigurations();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignSelf: isParcelFound ? 'baseline' : 'center',
        flexGrow: 1,
        width: '100%',
      }}
    >
      <Loader
        promiseFn={fetchParcel}
        pendingProps={{
          sx: {
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            alignItems: 'center',
            p: 2,
            mt: { xs: -4, md: 0 },
          },
        }}
        parcelNumber={parcelNumber}
        watch={parcelNumber}
        errorComponent={error => (
          <ErrorBoundary config={weHaveALittleProblem(error.message)} />
        )}
      >
        {isParcelFound ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              p: 2,
            }}
          >
            <ShareModal
              parcel={parcel}
              open={isShareModalOpen}
              onClose={() => setIsShareModalOpen(false)}
            />
            <OrderDetails
              parcel={parcel}
              showBackButton={isSmallScreen}
              footerButtons={
                <Button
                  variant='outlined'
                  size='medium'
                  onClick={() => setIsShareModalOpen(true)}
                >
                  {SHARE_TRACKING_INFO}
                </Button>
              }
              onBackClick={onBackClick}
            />
          </Box>
        ) : (
          <EmptySearchResults sx={{ mt: { xs: 0, md: 5 }, px: 2 }} />
        )}
      </Loader>
    </Box>
  );
};

export default TrackSection;
