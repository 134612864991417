import { Divider, Typography, Box } from '@mui/material';
import { upperFirst } from 'lodash';
import { PARCEL_$, PARCEL_TO_$ } from '../../../constants/strings';
import { getReceiverContactName } from '../../../helpers/shipment';
import { getParcelsQuantity } from '../../../utils/parcel';

export const BasketCardHeader = ({ shipment = {}, rightCardContent }) => {
  const { _networkDesc, parcels } = shipment.outboundConsignment || {};
  const receiverContactName = getReceiverContactName(shipment);
  const parcelsQuantity = getParcelsQuantity(parcels);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          padding: 2,
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ maxWidth: '100%', mr: { xs: 1, md: 2 }, flexGrow: 1 }}>
          <Box>
            <Typography
              variant='h3'
              sx={{
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '24px',
                overflow: 'hidden',
                wordBreak: 'break-word',
              }}
            >
              {upperFirst(
                receiverContactName
                  ? PARCEL_TO_$(receiverContactName, parcelsQuantity)
                  : PARCEL_$(parcelsQuantity)
              )}
            </Typography>
          </Box>
          <Box>
            {_networkDesc && (
              <Typography
                variant='body2'
                sx={{
                  fontSize: '12px',
                  fontWeight: 370,
                  letterSpacing: '0.17px',
                }}
              >
                {_networkDesc}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', flexGrow: 1 }}>
          {rightCardContent}
        </Box>
      </Box>
      <Divider />
    </>
  );
};
