import { Box, Typography } from '@mui/material';

import { SUMMARY_DETAILS, DEFAULT_PRICE } from '../../../constants/strings';
import { Formatters } from '../../../utils';

const TotalPrice = ({ totalIPrice, taxAmount }) => (
  <Box
    sx={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      py: 1,
    }}
    xs={12}
  >
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
      xs={12}
    >
      <Typography variant='h3' component='span' color='primary.main'>
        {SUMMARY_DETAILS.TOTAL}
      </Typography>

      <Typography variant='h3' color='primary.main'>
        {Formatters.formatPrice(totalIPrice) || DEFAULT_PRICE}
      </Typography>
    </Box>

    <Typography
      variant='body2'
      component='span'
      color='text.secondary'
      sx={{
        alignSelf: 'flex-end',
      }}
    >
      {SUMMARY_DETAILS.INCLUDES_VAT_$(
        Formatters.formatPrice(taxAmount) || DEFAULT_PRICE
      )}
    </Typography>
  </Box>
);

export default TotalPrice;
