import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { ADD_ADDRESS_PICKUP_POINT } from '../../../constants/analytics';
import { getPickupAddress, getPickupInfo } from '../../../utils/pickupPoint';
import PickupInfo from './PickupInfo';
import PickupDetails from './PickupDetails';
import AddressView from './AddressView';

const StyledBox = styled(Box)(({ theme, isActive }) => ({
  padding: theme.spacing(1, 3),
  backgroundColor: `${isActive ? theme.palette.action.hover : 'none'}`,
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.primary.borderLight}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const PickupPointItemButtonsBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const PickupPointItem = ({
  pickupPoint,
  onSelectClick,
  setActivePickupPoint,
  directionLink,
  isActive,
  showSelectButton,
  isModal,
  onChange,
}) => {
  const tracker = useTracker();
  const handleClick = () => {
    onSelectClick();
  };

  const onClose = () => {
    setActivePickupPoint(null);
  };

  return (
    <StyledBox id='pickup_point_item' isActive={isActive} onClick={handleClick}>
      <AddressView
        address={getPickupAddress(pickupPoint)}
        titleWidth={'100%'}
        pickupPoint={pickupPoint}
      />
      <PickupInfo showIsOpen={true} {...getPickupInfo(pickupPoint)} />{' '}
      <PickupPointItemButtonsBox />
      {isActive && (
        <PickupDetails
          pickupPoint={pickupPoint}
          directionLink={directionLink}
          showSelectButton={showSelectButton}
          isModal={isModal}
          onChange={onChange}
          onClose={onClose}
          onDirectionsClick={() => {
            tracker.logEvent(ADD_ADDRESS_PICKUP_POINT.ON_CLICK_DIRECTIONS);
          }}
          {...getPickupInfo(pickupPoint)}
        />
      )}
    </StyledBox>
  );
};

PickupPointItem.propTypes = {
  number: PropTypes.number.isRequired,
  pickupPoint: PropTypes.object.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  setActivePickupPoint: PropTypes.func,
  onPickupDetailsClick: PropTypes.func.isRequired,
  directionLink: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onDirectionsClick: PropTypes.func,
  showSelectButton: PropTypes.bool,
};

export default PickupPointItem;
