import { StringUtil } from '../utils';

export const COMPANY_NAME = 'Company name';
export const ADDRESS_LINE_1 = 'Address Line 1';
export const ADDRESS_LINE_2 = 'Address Line 2';
export const CITY = 'City';
export const COUNTY = 'County';
export const POSTAL_ZIP_CODE = 'Postal/Zip code';
export const COUNTRY = 'Country';
export const CURRENCY = 'Currency';
export const CUSTOMS_VALUE = 'Customs Value';
export const CUSTOMS_VALUE_$ = `${CUSTOMS_VALUE} ({0})`;
export const REASON_FOR_EXPORT = 'Reason for export';
export const QUANTITY = 'Quantity';
export const VALUE = 'Value (£)';
export const WEIGHT = 'Weight (kg)';
export const HEIGHT = 'Height (cm)';
export const WIDTH = 'Width (cm)';
export const LENGTH = 'Length (cm)';
export const CONTACT_NAME = 'Contact Name';
export const EMAIL = 'Email';
export const PHONE_NUMBER = 'Phone Number';
export const VAT_NUMBER = 'VAT Number';
export const EORI_NUMBER = 'EORI Number';
export const PID_NUMBER = 'PID Number';
export const UKIMS_NUMBER = 'UKIMS Number';
export const YOU_ARE = 'You are a';
export const RECIPIENT_IS = 'Recipient is a';
export const YOUR = 'Your';
export const RECEIVERS = "Receiver's";
export const PID = 'PID';
export const EORI = 'EORI';
export const VAT = 'VAT';
export const IOSS = 'IOSS';
export const FDA_REGISTRATION = 'FDA Registration';
export const UKIMS = 'UKIMS';
export const $_$_NUMBER = '{0} {1} Number';
export const MOBILE_NUMBER = 'Mobile number';

export const FIELDS = {
  DESTINATION_COUNTRY: {
    KEY: 'destinationCountry',
    LABEL: 'Destination Country',
  },
  ADDRESS_KEY: {
    KEY: 'addressId',
    LABEL: 'City or Postcode',
  },
  POSTCODE: {
    KEY: 'postcode',
    LABEL: 'Start typing an address',
    PLACEHOLDER: 'Start typing an address',
  },
  COUNTRY: {
    KEY: 'country',
    LABEL: COUNTRY,
  },
  COMMODITY: {
    KEY: 'commodity',
    LABEL: 'Search term',
    PLACEHOLDER: 'Search term',
  },
  ADDRESS: {
    KEY: 'address',
    LABEL: 'Start typing your address',
  },
  ADDRESS_COORDINATES: {
    KEY: 'addressCoordinates',
    LABEL: 'Coordinates',
  },
  EMAIL: {
    KEY: 'email',
    LABEL: 'Email Address',
    PLACEHOLDER: 'Enter your email address',
  },
  PARCEL_NUMBER: {
    KEY: 'parcelNumber',
    LABEL: 'Enter parcel number',
    PLACEHOLDER: 'Enter parcel number',
  },
  PHONE_NUMBER: {
    KEY: 'phoneNumber',
    LABEL: MOBILE_NUMBER,
  },
  PIN: {
    KEY: 'pin',
  },
};

export const COLLECTION_DETAILS_KEY = 'outboundConsignment.collectionDetails';
export const COLLECTION_ADDRESS_KEY = `${COLLECTION_DETAILS_KEY}.address`;
export const DELIVERY_DETAILS_KEY = 'outboundConsignment.deliveryDetails';
export const DESTINATION_ADDRESS_KEY = `${DELIVERY_DETAILS_KEY}.address`;

export const SHIPMENT_FIELDS = {
  DESTINATION_ADDRESS: {
    KEY: DESTINATION_ADDRESS_KEY,
  },
  DESTINATION_COUNTRY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.countryCode`,
    LABEL: 'Destination Country',
  },
  DESTINATION_ADDRESS_KEY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.${FIELDS.ADDRESS_KEY.KEY}`,
  },
  DESTINATION_ADDRESS_COORDINATES: {
    KEY: `${DESTINATION_ADDRESS_KEY}._coordinates`,
  },
  DESTINATION_POSTCODE: {
    KEY: `${DESTINATION_ADDRESS_KEY}.postcode`,
    LABEL: POSTAL_ZIP_CODE,
  },
  DESTINATION_COUNTY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.county`,
    LABEL: COUNTY,
  },
  DESTINATION_CITY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.town`,
    LABEL: CITY,
  },
  DESTINATION_ORGANISATION: {
    KEY: `${DESTINATION_ADDRESS_KEY}.organisation`,
    LABEL: COMPANY_NAME,
  },
  DESTINATION_STREET: {
    KEY: `${DESTINATION_ADDRESS_KEY}.street`,
    LABEL: ADDRESS_LINE_1,
  },
  DESTINATION_LOCALITY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.locality`,
    LABEL: ADDRESS_LINE_2,
  },
  COLLECTION_DETAILS: {
    KEY: COLLECTION_DETAILS_KEY,
  },
  COLLECTION_ADDRESS: {
    KEY: COLLECTION_ADDRESS_KEY,
  },
  COLLECTION_COUNTRY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.countryCode`,
    LABEL: COUNTRY,
  },
  COLLECTION_ADDRESS_KEY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.${FIELDS.ADDRESS_KEY.KEY}`,
  },
  COLLECTION_ADDRESS_COORDINATES: {
    KEY: `${COLLECTION_ADDRESS_KEY}._coordinates`,
  },
  COLLECTION_POSTCODE: {
    KEY: `${COLLECTION_ADDRESS_KEY}.postcode`,
    LABEL: POSTAL_ZIP_CODE,
  },
  COLLECTION_CITY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.town`,
    LABEL: CITY,
  },
  COLLECTION_COUNTY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.county`,
    LABEL: COUNTY,
  },
  COLLECTION_ORGANISATION: {
    KEY: `${COLLECTION_ADDRESS_KEY}.organisation`,
    LABEL: COMPANY_NAME,
  },
  COLLECTION_STREET: {
    KEY: `${COLLECTION_ADDRESS_KEY}.street`,
    LABEL: ADDRESS_LINE_1,
  },
  COLLECTION_LOCALITY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.locality`,
    LABEL: ADDRESS_LINE_2,
  },
  TOTAL_WEIGHT: {
    KEY: 'outboundConsignment.totalWeight',
  },
  NUMBER_OF_PARCELS: {
    KEY: 'outboundConsignment.numberOfParcels',
  },
  PICKUP_DETAILS: {
    KEY: 'outboundConsignment.pickupDetails',
  },
  PICKUP_LOCATION_CODE: {
    KEY: 'outboundConsignment.pickupDetails.pickupLocationCode',
  },
  DROP_AT_SHOP: {
    KEY: 'outboundConsignment.collectionDetails.dropAtShop',
  },
  CURRENCY: {
    KEY: 'outboundConsignment.currency',
    LABEL: CURRENCY,
  },
  CUSTOMS_VALUE: {
    KEY: 'outboundConsignment.customsValue',
    LABEL: currency => StringUtil.formatMessage(CUSTOMS_VALUE_$, currency),
  },
  LIABILITY: {
    KEY: 'outboundConsignment.liability',
  },
  LIABILITY_VALUE: {
    KEY: 'outboundConsignment.liabilityValue',
  },
  DELIVERY_DESCRIPTION: {
    KEY: 'deliveryDescription',
    LABEL: 'Parcel description (optional)',
  },
  NETWORK_CODE: {
    KEY: 'outboundConsignment.networkCode',
  },
  NETWORK_DESC: {
    KEY: 'outboundConsignment._networkDesc',
  },
  NETWORK_BUS_ZONE: {
    KEY: 'outboundConsignment._networkBusZone',
  },
  NETWORK_EXPORT_IMPORT_REQUIRED: {
    KEY: 'outboundConsignment._exportImportRequired',
  },
  NETWORK_EORI_REQUIRED: {
    KEY: 'outboundConsignment._eoriRequired',
  },
  NETWORK_VAT_NUMBER: {
    KEY: 'outboundConsignment._vatRequired',
  },
  NETWORK_PID_NUMBER: {
    KEY: 'outboundConsignment._pidRequired',
  },
  NETWORK_UKIMS_NUMBER: {
    KEY: 'outboundConsignment._ukimsRequired',
  },
  NETWORK_EXPORT_REASON_REQUIRED: {
    KEY: 'outboundConsignment._exportReasonRequired',
  },
  NETWORK_DT1_AVAILABLE: {
    KEY: 'outboundConsignment._dt1Available',
  },
  NETWORK_DDP_AVAILABLE: {
    KEY: 'outboundConsignment._ddpAvailable',
  },
  NETWORK_FDA_REQUIRED: {
    KEY: 'outboundConsignment._fdaRequired',
  },
  NETWORK_COST_REQUIRED: {
    KEY: 'outboundConsignment._costsRequired',
  },
  NETWORK_PROFORMA: {
    KEY: 'outboundConsignment._proforma',
  },
  SHIPMENT_DATE: {
    KEY: 'shipmentDate',
    LABEL: 'Select a collection date',
  },
  PARCELS: {
    KEY: 'outboundConsignment.parcels',
  },
  PARCEL_HEIGHT: {
    KEY: index => `outboundConsignment.parcels[${index}]._height`,
    LABEL: HEIGHT,
  },
  PARCEL_WIDTH: {
    KEY: index => `outboundConsignment.parcels[${index}]._width`,
    LABEL: WIDTH,
  },
  PARCEL_LENGTH: {
    KEY: index => `outboundConsignment.parcels[${index}]._length`,
    LABEL: LENGTH,
  },
  PARCEL_WEIGHT: {
    KEY: index => `outboundConsignment.parcels[${index}]._weight`,
    LABEL: WEIGHT,
  },
  PARCEL_QUANTITY: {
    KEY: index => `outboundConsignment.parcels[${index}]._quantity`,
    LABEL: QUANTITY,
  },
  PARCEL_VALUE: {
    KEY: index => `outboundConsignment.parcels[${index}]._value`,
    LABEL: VALUE,
  },
  PARCEL_DESCRIPTION: {
    KEY: index => `outboundConsignment.parcels[${index}]._description`,
    LABEL: 'Parcel description (optional)',
  },
  DROP_OFF_DETAILS: {
    KEY: 'outboundConsignment._dropOffDetails',
  },
  DROP_OFF_PICKUP_LOCATION_CODE: {
    KEY: 'outboundConsignment._dropOffDetails.pickupLocationCode',
  },
  COLLECT_FROM: {
    KEY: 'outboundConsignment.collectionDetails._collectFrom',
  },
  DELIVER_TO: {
    KEY: 'outboundConsignment.deliveryDetails._deliverTo',
  },

  // address details fields
  COLLECTION_ADDRESS_BOOK_ID: {
    KEY: 'outboundConsignment.collectionDetails.contactDetails._addressBookId',
  },
  COLLECTION_CONTACT_NAME: {
    KEY: 'outboundConsignment.collectionDetails.contactDetails.contactName',
  },
  COLLECTION_CONTACT_EMAIL: {
    KEY: 'outboundConsignment.collectionDetails.contactDetails.email',
  },
  COLLECTION_CONTACT_TELEPHONE: {
    KEY: 'outboundConsignment.collectionDetails.contactDetails.telephone',
  },
  DELIVERY_DETAILS: {
    KEY: 'outboundConsignment.deliveryDetails',
  },
  DELIVERY_ADDRESS_BOOK_ID: {
    KEY: 'outboundConsignment.deliveryDetails.contactDetails._addressBookId',
  },
  DELIVERY_CONTACT_NAME: {
    KEY: 'outboundConsignment.deliveryDetails.contactDetails.contactName',
  },
  DELIVERY_CONTACT_EMAIL: {
    KEY: 'outboundConsignment.deliveryDetails.notificationDetails.email',
  },
  DELIVERY_CONTACT_MOBILE: {
    KEY: 'outboundConsignment.deliveryDetails.notificationDetails.mobile',
  },
  INVOICE_EXPORT_REASON: {
    KEY: 'invoice.exportReason',
    LABEL: REASON_FOR_EXPORT,
  },
  INVOICE_EXPORTER_EORI_NUMBER: {
    KEY: 'invoice.exporterDetails.eoriNumber',
    LABEL: StringUtil.formatMessage($_$_NUMBER, YOUR, EORI),
  },
  INVOICE_EXPORTER_VAT_NUMBER: {
    KEY: 'invoice.exporterDetails.vatNumber',
    LABEL: StringUtil.formatMessage($_$_NUMBER, YOUR, VAT),
  },
  INVOICE_EXPORTER_UKIMS_NUMBER: {
    KEY: 'invoice.exporterDetails.ukimsNumber',
    LABEL: StringUtil.formatMessage($_$_NUMBER, YOUR, UKIMS),
  },
  INVOICE_EXPORTER_FDA_NUMBER: {
    KEY: 'invoice.exporterDetails.fdaRegistrationNumber',
    LABEL: StringUtil.formatMessage($_$_NUMBER, YOUR, FDA_REGISTRATION),
  },
  INVOICE_EXPORTER_IOSS_NUMBER: {
    KEY: 'invoice.exporterDetails.iossNumber',
    LABEL: StringUtil.formatMessage($_$_NUMBER, YOUR, IOSS),
  },
  INVOICE_EXPORTER_IS_BUSINESS: {
    KEY: 'invoice.exporterDetails.isBusiness',
    LABEL: YOU_ARE,
  },
  INVOICE_IMPORTER_EORI_NUMBER: {
    KEY: 'invoice.importerDetails.eoriNumber',
    LABEL: StringUtil.formatMessage($_$_NUMBER, RECEIVERS, EORI),
  },
  INVOICE_IMPORTER_VAT_NUMBER: {
    KEY: 'invoice.importerDetails.vatNumber',
    LABEL: StringUtil.formatMessage($_$_NUMBER, RECEIVERS, VAT),
  },
  INVOICE_IMPORTER_UKIMS_NUMBER: {
    KEY: 'invoice.importerDetails.ukimsNumber',
    LABEL: StringUtil.formatMessage($_$_NUMBER, RECEIVERS, UKIMS),
  },
  INVOICE_IMPORTER_PID_NUMBER: {
    KEY: 'invoice.importerDetails.pidNumber',
    LABEL: StringUtil.formatMessage($_$_NUMBER, RECEIVERS, PID),
  },
  INVOICE_IMPORTER_IS_BUSINESS: {
    KEY: 'invoice.importerDetails.isBusiness',
    LABEL: RECIPIENT_IS,
  },
  INVOICE_IMPORTER_AT_RISK: {
    KEY: 'invoice.importerDetails.atRisk',
  },
};

export const ADDRESS_BOOK_FIELDS = {
  ADDRESS_KEY: {
    KEY: 'address.addressId',
  },
  ADDRESS_COORDINATES: {
    KEY: 'address.coordinates',
  },
  COUNTRY: {
    KEY: 'address.countryCode',
    LABEL: COUNTRY,
  },
  ADDRESS: {
    KEY: 'address.street',
    LABEL: ADDRESS_LINE_1,
  },
  LOCALITY: {
    KEY: 'address.locality',
    LABEL: 'Locality',
  },
  CITY: {
    KEY: 'address.town',
    LABEL: CITY,
  },
  COUNTY: {
    KEY: 'address.county',
    LABEL: COUNTY,
  },
  POSTCODE: {
    KEY: 'address.postcode',
    LABEL: 'Postcode',
  },
  CONTACT_NAME: {
    KEY: 'contactName',
    LABEL: CONTACT_NAME,
  },
  ORGANISATION: {
    KEY: 'address.organisation',
    LABEL: COMPANY_NAME,
  },
  EMAIL: {
    KEY: 'email',
    LABEL: EMAIL,
  },
  PHONE_NUMBER: {
    KEY: 'phoneNumber',
    LABEL: PHONE_NUMBER,
  },
  VAT_NUMBER: {
    KEY: 'vatNumber',
    LABEL: VAT_NUMBER,
  },
  EORI_NUMBER: {
    KEY: 'eoriNumber',
    LABEL: EORI_NUMBER,
  },
  PID_NUMBER: {
    KEY: 'pidNumber',
    LABEL: PID_NUMBER,
  },
  UKIMS_NUMBER: {
    KEY: 'ukimsNumber',
    LABEL: UKIMS_NUMBER,
  },
  IS_DEFAULT: {
    KEY: 'isDefault',
    LABEL: 'This is my default address',
  },
  IS_BILLING: {
    KEY: 'isBilling',
    LABEL: 'This is my billing address',
  },
};

export const ACCOUNT_DETAILS_FIELDS = {
  COMMUNICATION_PREFERENCES: {
    SMS: {
      NAME: 'notifyBySms',
      LABEL: 'SMS',
    },
    EMAIL: {
      NAME: 'notifyByEmail',
      LABEL: 'Email',
    },
    UPDATE: {
      NAME: 'allowTrackingUpdates',
      LABEL: 'Parcel delivery notifications',
    },
  },
  ACCOUNT_DETAILS: {
    FIRST_NAME: {
      NAME: 'firstName',
      LABEL: 'First Name',
    },
    LAST_NAME: {
      NAME: 'lastName',
      LABEL: 'Last Name',
    },
    EMAIL_ADDRESS: {
      NAME: 'email',
      LABEL: 'Email Address',
    },
    PHONE_NUMBER: {
      NAME: 'phone',
      LABEL: 'Phone Number',
    },
  },
};
