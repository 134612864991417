import { useMemo } from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

import SectionWrapper from '../SectionWrapper/SectionWrapper';
import { generateRouteMapStaticImageUrlByParcelCode } from '../../utils/map';
import { useAuth } from '../../features/Auth';
import IssueSection from './components/IssueSection';
import TrackingSection from './components/TrackingSection';
import AddressSection from './components/AddressSection';
import DeliveryStatusSection from './components/DeliveryStatusSection';
import EventsSection from './components/EventsSection';

const OrderDetails = ({
  parcel,
  footerButtons,
  onBackClick,
  showBackButton = true,
}) => {
  const theme = useTheme();
  const auth = useAuth();
  const hideSensitiveInfo =
    !auth.currentSession?.uid || !auth.currentSession.basketId;

  const {
    parcelCode,
    collectionDetails,
    deliveryDetails,
    parcelEvents = [],
    parcelNumber,
    parcelStatus,
    parcelStatusHtml,
    service,
    issueDetails,
  } = { ...parcel };

  let images = [];

  const contactNameOrOrganisation =
    deliveryDetails?.notificationDetails?.contactName ??
    deliveryDetails?.address?.organisation;

  if (parcelEvents?.length > 0) {
    const firstEvent = parcelEvents[0];
    images = firstEvent?.images || [];
  }

  const mapSrc = useMemo(() => {
    if (parcel?.outForDeliveryDetails?.mapAvailable) {
      return generateRouteMapStaticImageUrlByParcelCode({
        parcelCode,
        width: 600,
        height: 340,
      });
    }
    return null;
  }, [parcel?.outForDeliveryDetails?.mapAvailable, parcelCode]);

  return (
    <>
      {parcel ? (
        <SectionWrapper
          titleLeftComponent={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {showBackButton && (
                <IconButton
                  aria-label='back'
                  sx={{ mr: 0.5, p: 0.5 }}
                  onClick={onBackClick}
                >
                  <ChevronLeft />
                </IconButton>
              )}
              <Box>
                {contactNameOrOrganisation && (
                  <Typography>{contactNameOrOrganisation}</Typography>
                )}
                <Typography
                  variant='body2'
                  color='textSecondary'
                  sx={{
                    fontFamily: 'Roboto',
                    m: 0,
                  }}
                >
                  {parcelNumber.slice(0, -1)}
                  {service?.serviceDescription
                    ? ` • ${service.serviceDescription}`
                    : ''}
                </Typography>
              </Box>
            </Box>
          }
          titleRightComponent={
            parcelStatus && (
              <Typography
                color={theme.palette.parcelStatus[parcelStatus]}
                sx={{ ml: 2, mt: { xs: 2, md: 0 } }}
              >
                {parcelStatus}
              </Typography>
            )
          }
          sx={{ height: 'auto' }}
        >
          <Box sx={{ maxWidth: 600 }}>
            <TrackingSection mapSrc={mapSrc} images={images} />
            <DeliveryStatusSection
              parcelStatus={parcelStatus}
              parcelStatusHtml={parcelStatusHtml}
              onRouteElectric={deliveryDetails?.onRouteElectric}
            />
            <IssueSection issueDetails={issueDetails} />
            {!hideSensitiveInfo && (
              <AddressSection
                collectionDetails={collectionDetails}
                deliveryDetails={deliveryDetails}
              />
            )}
            <EventsSection parcelEvents={parcelEvents} theme={theme} />
          </Box>
          <Divider sx={{ mt: 2, marginX: -2 }} />
          {footerButtons && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: { md: 'row', xs: 'column' },
                justifyContent: { md: 'flex-end', xs: 'center' },
                pt: 1,
                gap: 1,
              }}
            >
              {footerButtons}
            </Box>
          )}
        </SectionWrapper>
      ) : null}
    </>
  );
};

export default OrderDetails;
