import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { BasketActions } from '../../../redux/basket';
import {
  FILE_FORMAT,
  ORDER_DETAILS,
  ORDERS_SECTION,
} from '../../../constants/strings';
import parcelSlice from '../../../redux/parcelSlice';
import useBreakpoint from '../../../hooks/useBreakpoint';
import useParcelSearch from '../../../hooks/useParcelSearch';
import OrderDetails from '../../../components/OrderDetails/index';
import Loader from '../../../features/Loader';
import EmptySearchResults from '../../../components/EmptySearchResults';
import {
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../../../features/ErrorBoundary';
import { useOverlay } from '../../../features/Overlay';
import { useCustomSnackbar } from '../../../features/CustomSnackbar';
import { getOrderInvoiceDownloadUrl } from './utils/downloadUrl';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  minHeight: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const OrderView = () => {
  const { orderError } = useErrorBoundaryConfigurations();
  const isSmallScreen = useBreakpoint('sm');
  const { parcelNumber } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const { showError } = useCustomSnackbar();

  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigateToOrders = () => navigate('/profile/orders');

  const { data: parcel } = useSelector(
    parcelSlice.selectors.getParcel(parcelNumber)
  );

  const { fetchParcel, isParcelFound } = useParcelSearch();

  const handleActionMenuClick = event => {
    setActionMenuAnchorEl(event.currentTarget);
    setIsMenuOpen(prev => !prev);
  };

  const actionMenuBtnHandleClose = () => {
    setActionMenuAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleDownloadInvoice = (parcelNumber, format) => {
    const url = getOrderInvoiceDownloadUrl(parcelNumber, format);
    window.open(url, '_blank');
    actionMenuBtnHandleClose();
  };

  const sendAgainOrder = useCallback(
    async parcelCode => {
      try {
        overlay.show();

        const { basketItemId } = await dispatch(
          BasketActions.duplicateBasketItem(parcelCode)
        ).unwrap();

        navigate(`/order?basketItemId=${basketItemId}`);
      } catch (error) {
        showError({
          message: ORDERS_SECTION.NOTIFICATIONS.DUPLICATE_ORDER_ERROR,
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch, navigate, overlay, showError]
  );

  return (
    <Box
      sx={{
        background: theme => theme.palette.primary.pageBackground,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 2,
        paddingBottom: 6,
        paddingX: isSmallScreen ? 0 : 1,
      }}
    >
      <Loader
        promiseFn={fetchParcel}
        pendingProps={{
          sx: { marginTop: { xs: '50px', md: '100px' } },
        }}
        parcelNumber={parcelNumber}
        errorComponent={error =>
          ErrorBoundary({
            config: orderError(error.message),
            sx: {
              height: '100%',
              minHeight: '100%',
            },
          })
        }
      >
        {isParcelFound ? (
          <OrderDetails
            parcel={parcel}
            onBackClick={navigateToOrders}
            footerButtons={
              <>
                <Button
                  variant='outlined'
                  onClick={() => sendAgainOrder(parcel?.parcelCode)}
                >
                  {ORDER_DETAILS.SEND_AGAIN}
                </Button>
                <Button
                  variant='outlined'
                  sx={{ pr: 1 }}
                  onClick={handleActionMenuClick}
                  endIcon={
                    <ArrowDropDown
                      sx={{
                        transition: 'transform 0.3s',
                        transform: isMenuOpen
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      }}
                    />
                  }
                >
                  {ORDER_DETAILS.DOWNLOAD}
                </Button>
                <Menu
                  anchorEl={actionMenuAnchorEl}
                  open={!!actionMenuAnchorEl}
                  onClose={actionMenuBtnHandleClose}
                  MenuListProps={{
                    sx: { width: '100px' },
                  }}
                  anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                  }}
                  transformOrigin={{
                    horizontal: -26,
                    vertical: 125,
                  }}
                >
                  <StyledMenuItem
                    disableRipple
                    theme={theme}
                    onClick={() =>
                      handleDownloadInvoice(parcelNumber, FILE_FORMAT.PDF)
                    }
                  >
                    {ORDER_DETAILS.PDF}
                  </StyledMenuItem>
                  <StyledMenuItem
                    disableRipple
                    theme={theme}
                    onClick={() =>
                      handleDownloadInvoice(parcelNumber, FILE_FORMAT.CSV)
                    }
                  >
                    {ORDER_DETAILS.CSV}
                  </StyledMenuItem>
                </Menu>
              </>
            }
          />
        ) : (
          <EmptySearchResults
            button={
              <Button
                variant={'contained'}
                sx={{ mt: 2 }}
                onClick={navigateToOrders}
              >
                {ORDER_DETAILS.BACK_TO_ORDERS}
              </Button>
            }
          />
        )}
      </Loader>
    </Box>
  );
};

export default OrderView;
