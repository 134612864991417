import { AUTH_TOO_MANY_REQUESTS, REACH_MAX_LOGIN_ATTEMPTS_CODE } from './error';

// HOME PAGE
export const DPD_ONLINE = 'DPD Online';
export const SEND_PARCEL = 'Send a parcel';
export const SEND = 'Send';
export const SHIP_PRICE_FROM = 'From £3.99 exc VAT';
export const SEND_PARCEL_DESCRIPTION =
  'The choice is yours! Schedule a home collection or drop off at any of our 10,000+ DPD Pickup locations.';
export const EASY_INTENATIONAL_SHIPPING = 'International shipping made easy';
export const GO_GLOBAL = 'Go Global: Ship Worldwide';
export const INTERNATION_SHIPPING_DESCRIPTION =
  'Reach over 200 countries worldwide with DPD international shipping. Get affordable rates starting from just £5.81 exc VAT (with DPD Online)! Ship anywhere, for less. Choose DPD';
export const LABEL_FREE_DROP_OFF = 'Label-free Drop-off';
export const NO_PRINTER_NO_PROBLEM = 'No printer? No problem!';
export const FREE_LABEL_PRINTING_DESCRIPTION =
  "Thousands of DPD locations offer free label printing at drop-off. Just bring your digital label or QR code and we'll handle the rest.";
export const MULTI_PARCEL_SHIPPING = 'All-in-One Multi-Parcel Shipping';
export const BULK_SHIPPING_HEADER = "DPD Online's bulk shipping option";
export const BULK_SHIPPING_DESCRIPTION =
  "Businesses and high-volume shippers, save time! Book multiple parcels at once with DPD Online's bulk shipping option. Plus, integrate your eBay, Shopify, and Amazon accounts to automate orders and manage deliveries in one central hub. Streamline your shipping process and free up valuable time. ";
export const SUPPORTED_PLATFORMS = 'Supported platforms';
export const SUPPORTED_PLATFORMS_DESCRIPTION =
  'Simplify your workflow. Link your account to DPD and experience streamlined booking and bulk shipping';
export const SEND_NOW = 'Send now';
export const DPD_GROUP_LTD = 'DPDgroup UK Ltd.';
export const ALL_RIGHTS_RESERVED = 'All rights reserved.';
export const DPD_REGISTERED_IN =
  'Registered in England and Wales: company no. 00732993.';
export const DPD_REGISTERED_OFFICE =
  'Registered office: Roebuck Lane, Smethwick, B66 1BY.';
export const SOCIAL_MEDIA = 'Social media';
export const TERMS_AND_CONDITIONS = 'Terms & Conditions';
export const PRIVACY_NOTICE = 'Privacy Notice';
export const COOKIE_POLICY = 'Cookie Policy';
export const CYBER_SECURITY = 'Cyber Security';
export const POLICY_FOR_DISABLED_CONSUMERS =
  'Policy to support disabled consumers';
export const FUEL_ENERGY_SURCHARGE = 'Fuel and Energy Surcharge';
export const GEOPOST = 'Geopost';
export const EMAIL_LINK = 'Email link';

export const EMAIL_TEMPLATE_SUBJECT = 'Your DPD Order Details';
export const EMAIL_TEMPLATE_BODY_$ = link =>
  `Here is the link to your order: ${link}`;

// AUTH PAGES
export const SIGN_IN_WITH_EMAIL = 'Sign in using your email address';
export const SIGN_IN_WITH_EMAIL_DESCRIPTION =
  "By choosing to sign in using your email address, we'll just need to check if you already have an account or we'll give you the option to quickly create a new one.";
export const SIGN_IN = 'Sign In';
export const LOGOUT = 'Logout';
export const CHECK_YOUR_EMAIL = 'Check your email';
export const SEND_ON_EMAIL_$ = email => `We've sent an email to ${email}`;
export const CHECK_YOUR_EMAIL_DESCRIPTION =
  'This email will contain a magic link that will automatically sign you in.';
export const EMAIL_NOT_RECEIVE = `I didn't receive my email`;
export const BACK_TO_HOME = 'Back to home';
export const HOW_WE_USE_YOUR_DATA = 'This is how DPD will use your data:';
export const TO_KEEP_YOU_INFORMED = 'To keep you informed about your parcels';
export const NEVER_USE_YOUR_DATA_TO = 'DPD will never use your data to:';
export const ENSURE_OUR_SERVICES =
  'Ensure our services continue to meet your needs';
export const FOR_YOUR_PARCEL_PREFERENCES =
  'For your parcel preferences to be stored for a great delivery experience';
export const NEVER_SELL_YOUR_DATA =
  'We will never sell your data to any third parties';
export const NO_KEEP_DATA_LONGER_THAN_NEEDED =
  'We will never keep your data for longer than what is needed';
export const NO_SEND_MARKETING_INFO =
  'We will never use your data to send you marketing information';
export const NEXT = 'Next';
export const BACK = 'Back';
export const NEXT_STEP = 'Next step';
export const PLEASE_SEE_OUR_POLICY =
  'Please see our Privacy Policy and Terms & Conditions for more details on how your personal data will be used.';
export const I_ACCEPT_TERMS = 'I agree to the Terms & Conditions';
export const ANOTHER_EMAIL_CHECK = 'We’ve sent another email, check your inbox';
export const CHECK_SPAM_TOO = 'Please be sure to check your spam folder too.';
export const YOUR_PICKUP_POINT = 'Your Pickup point';
export const RECEIVER_PICKUP_POINT = 'Receiver’s Pickup point';
export const CHANGE_YOUR_PICKUP_POINT = 'Change Your Pickup point';
export const SIGN_IN_WITH_EMAIL_EXPIRED =
  'Sorry, but it looks like the link you’re trying to use has now expired';
export const SIGN_IN_WITH_EMAIL_DESC_EXPIRED = `Don't worry, we can quickly send you a new magic link so that you can sign in to your DPD Online`;
export const PLEASE_PROVIDE_YOUR_PHONE =
  'Please provide your mobile number so we can keep you informed about your parcels';
export const VALIDATE_YOUR_MOBILE = 'Validate your mobile number';
export const ENTER_PIN = 'Enter a pin which is 6 digits long';
export const LOGIN_ERROR = 'Error while logging in';
export const RESEND_PIN = 'Resend pin';
export const RESEND_PIN_WITH_TIMER_$ = resendPinTimer =>
  `RESEND PIN (${resendPinTimer})`;

// TRACK PAGE
export const TRACK_YOUR_PARCEL = 'Track your parcel';
export const TRACK = 'Track';
export const FOUND_REFERENCE_NUMBER =
  'Your reference number can be found in your order confirmation email or on the printed label.';
export const DID_YOU_KNOW = 'Did you know?';
export const CUSTOMIZE_APP_TEXT =
  'You can customize your delivery preferences in the DPD app?';
export const DOWNLOAD_APP_TEXT =
  'Download the app today from the Apple App Store or Google Play Store';
export const DOWNLOAD_ON_APPLE = 'DOWNLOAD ON APPLE APP STORE';
export const DOWNLOAD_ON_GOOGLE = 'DOWNLOAD ON GOOGLE PLAY';
export const SHARE_TRACKING_INFO = 'SHARE TRACKING INFO';
export const NO_PARCELS_TO_SHOW = 'No parcels to show';
export const FETCHING_PARCEL_ERROR = 'Error while loading parcel';
export const PLEASE_ENTER_VALID_$ = 'Please enter a valid {0} ';
export const PARCEL_NUMBER_IS_NOT_VALID = 'Your parcel number is not valid.';

// BASKET PAGE
export const YOUR_CART = 'Your Cart';
export const PARCEL_$ = parcelsQuantity =>
  `parcel${parcelsQuantity > 1 ? 's' : ''}`;
export const SHIPMENT_$ = shipmentQuantity =>
  `shipment${shipmentQuantity > 1 ? 's' : ''}`;
export const PARCEL_TO_$ = (contactName, parcelsQuantity) =>
  `${PARCEL_$(parcelsQuantity)} to ${contactName}`;
export const EDIT = 'Edit';
export const REMOVE = 'Remove';
export const PROCESS_TO_CHECKOUT = 'Proceed to Checkout';
export const PROCESS_$_SHIPMENT = shipmentsQuantity =>
  `Proceed to Checkout - ${shipmentsQuantity} ${SHIPMENT_$(shipmentsQuantity)}`;
export const REMOVE_FROM_CHECKOUT = 'Remove from checkout';
export const ADD_TO_CHECKOUT = 'Add to checkout';
export const WANT_TO_REMOVE_$ = itemToRemove =>
  `Are you sure you want to remove ${itemToRemove}?\nThis action cannot be undone once removed.`;
export const EMPTY_CARD = 'Your cart is empty';
export const TO_BE_DROPPED_OFF = 'to drop-off at your convenience';
export const TO_BE_COLLECTED = 'to be collected';
export const FREE = 'Free';
export const DEFAULT_PRICE = '0.00';
export const DROP_OFF_POINT = 'Drop off point:';
export const DROP_OFF_TO_PICKUP = 'Drop off to Pickup Point';
export const DIMENSIONS_$ = (height, length, width) =>
  `${height}cm x ${length}cm x ${width}cm`;
export const INCOMPLETE_ORDER = 'Incomplete order';
export const ORDER_MISSING_INFO =
  'Your order has missing information and needs to be edited';

// ORDER SUMMARY
export const ORDER_SUMMARY = 'Order summary';
export const VIEW_YOUR_ORDERS =
  'View your orders below. Please prepare your labels and retrieve your QR codes. This page will also be emailed to you.';
export const VIEW_INSTRUCTIONS = 'View instructions';

// VOUCHER
export const YOUR_VOUCHER = 'Your Voucher';
export const ENTER_VOUCHER = 'Enter your voucher code';
export const APPLY = 'Apply';
export const VOUCHER_APPLIED = 'Voucher applied';
export const INVALID_VOUCHER = 'Voucher code is invalid';
export const APPLIED_VOUCHER_$ = voucherCode =>
  `Voucher code '${voucherCode}' applied`;

// VALIDATION
export const REQUIRED = 'Required';
export const SHOULD_CONTAIN_ONE_CHARACTER =
  'Should contain at least one non-whitespace character';
export const INVALID_POSTCODE = 'Invalid postcode';
export const INVALID_MOBILE_NUMBER = 'Invalid mobile number';
export const MUST_BE_NUMBER = 'Must be a number';
export const GREATER_THAN_$ = min => `Should be greater than ${min}`;
export const LESS_OR_$_CHARACTERS = max => `Must be ${max} characters or less`;
export const GREATER_OR_$_CHARACTERS = min =>
  `Must be ${min} characters or greater`;
export const LESS_THAN_OR_$_NUMBER = max => `Must be less than or equal ${max}`;
export const GREATER_THAN_OR_$_NUMBER = max =>
  `Must be greater than or equal ${max}`;
export const MAX_PARCEL_WEIGHT_$ = max => `Maximum parcel weight ${max}kg`;
export const MAX_PARCEL_HEIGHT_$ = max => `Maximum parcel height ${max}cm`;
export const MAX_PARCEL_WIDTH_$ = max => `Maximum parcel width ${max}cm`;
export const MAX_PARCEL_LENGTH_$ = max => `Maximum parcel length ${max}cm`;
export const MAX_QUANTITY_$ = max => `(Maximum ${max})`;
export const CUSTOM_VALIDATION_ERROR_MESSAGES = {
  'postcode.bfpo.invalid':
    'The British Forces Post Office postcodes are not allowed',
  'string.pattern.base':
    'Invalid postal/zip code format. Please use a valid UK postal code format.',
  'postcode.country.invalid': 'Invalid postcode for selected country',
};

// ERRORS
export const LOST_INTERNET_CONNECTION =
  'Sorry, it looks like you’ve lost connection. Please connect to the internet and try again';

export const REFRESH = 'Refresh';

// BUTTONS
export const DIRECTIONS = 'Directions';
export const CANCEL = 'Cancel';
export const CLOSE = 'CLOSE';

// PICKUP POINTER
export const OPENS = 'Opens';
export const CLOSES = 'Closes';
export const CLOSED_FOR_LUNCH = 'Closed for lunch';
export const SHOP = 'Shop';
export const LOCKER = 'Locker';
export const MILES = 'mi';
export const CLOSED = 'Closed';
export const OPEN = 'Open';
export const CLOSED_FOR_LUNCH_BETWEEN = 'Closed for lunch between';
export const DETAILS = 'Details';
export const SELECTED = 'Selected';
export const SELECT = 'Select';
export const PICKUP_POINT_LIST_TITLE = 'Pickup Point Finder';
export const SELECTED_PICKUP_POINT = 'Select Pickup Point';

// SEARCH
export const START_TYPING_ADDRESS = 'Start typing an address';
export const NO_RESULT = {
  title: 'Sorry,',
  description: 'There are no Pickup Points available in this area.',
};
export const NO_SEARCH_SEARCH_RESULT = 'No search results found';

// PARCEL_DETAILS
export const WHERE_YOUR_PARCEL_GOES = 'Where is your parcel going to?';
export const CHOOSE_FROM_ADDRESS_BOOK = 'Choose From Address Book';
export const WHERE_YOUR_PARCEL_FROM = 'Where are you sending it from?';
export const HOW_BIG_IS_YOUR_PARCEL = 'How big is your parcel?';
export const VIEW_MAX_PARCEL_SIZES = 'View maximum parcel sizes';
export const ADD_ANOTHER_PARCEL = 'Add another parcel';
export const DELETE_PARCEL = 'Delete parcel';
export const WHERE_DELIVER_PARCEL_TO = 'Where should we deliver your parcel?';
export const VIEW_NEARBY_PICKUPS = 'View nearby DPD Pickup points';
export const SELECT_DPD_PICKUP_POINT = 'Select DPD Pickup Point';
export const DIRECT_TO_PICKUP = 'Direct to Pickup Point';
export const DIRECT_TO_PICKUP_DESCRIPTION =
  'Send your parcel directly to one of our Pickup Points for the recipient to collect';
export const MOST_POPULAR = 'Most popular';
export const RECOMMENDED = 'Recommended';
export const DELIVER_TO_RECIPIENT = 'Deliver to the recipient';
export const DELIVER_TO_RECIPIENT_DESCRIPTION =
  'Your parcel will be delivered directly to the recipient’s address';
export const WHEN_DELIVER = 'When would you like it to arrive?';
export const WHEN_ARRIVE = 'When will it arrive?';
export const WOULD_YOU_LIKE_COLLECTION =
  'Would you like us to collect your parcel?';
export const YES_COLLECT = 'Yes, collect my parcel';
export const YES_COLLECT_DESCRIPTION =
  'We will collect your parcel at the address you are going to provide us in the next step.';
export const NO_DROP_OFF = 'No, I’ll drop it off';
export const NO_DROP_OFF_DESCRIPTION =
  'You can drop off your parcel at any of our Pickup Points near to you.';
export const COLLECTION_DATE_HEADER = 'When would you like us to collect?';
export const WOULD_YOU_LIKE_INSURANCE =
  'Would you like to add extra insurance?';
export const MAX_PARCEL_SIZES = 'Maximum Parcel Sizes';
export const SHOP_SIZES_HEADING = 'Shops - Drop-off or collection';
export const SHOP_SIZES_TEXT =
  "The longest side of your box or bag, plus the next-longest side added together, can't be more than 120cm. Max weight is 20kg.";
export const LOCKER_SIZES_HEADING = 'Locker - Drop-off or collection';
export const LOCKER_SIZES_TEXT =
  'The biggest box or bag that will fit in the locker measures 39cm long, 55 cm wide, and 35cm deep (or tall).';
export const HOME_SIZES_HEADING = 'Home - Collection and delivery';
export const HOME_SIZES_TEXT =
  'The largest parcel that can be collected and delivered to a residential address is 100 x 100 x 100cm';
export const AVAILABLE_SERVICES = 'Available services';
export const WE_ARE_CALCULATING_SERVICES =
  'We’re just calculating our best services for you';
export const PARCEL_WEIGHT = 'Parcel weight';
export const WEIGHT = 'Weight';
export const WEIGHT_PER_PARCEL = 'Weight per parcel';
export const QUANTITY = 'Quantity';
export const QUANTITY_DESCRIPTION =
  'Are you sending multiples of the same parcel?';
export const VALUE = 'Value';
export const VALUE_DESCRIPTION =
  'What is the value of the contents of your parcel?';
export const CHOSEN_PICKUP_POINT = 'Your chosen Pickup Point:';
export const HEIGHT = 'Height';
export const WIDTH = 'Width';
export const LENGTH = 'Length';
export const PARCEL_DESCRIPTION = 'Parcel description';
export const PARCEL_FETCHING_ERROR = 'Error while loading parcel details';
export const FETCHING_ERROR = 'Error while loading';
export const NO_NETWORKS_ERROR = 'No suitable Product/Services found';
export const FAILED_TO_SAVE_BASKET =
  'Failed to save basket item. Please try again';
export const FAILED_TO_DELETE_BASKET_ITEM =
  'Failed to delete basket item. Please try again';
export const NO_NETWORKS_FOUND = 'No suitable Product/Services found';
export const GBP = 'GBP';
export const EUR = 'EUR';
export const USD = 'USD';
export const TRY_AGAIN = 'Try again';
export const EMAIL = 'email';
export const PHONE_NUMBER = 'phone number';
export const MOBILE_NUMBER = 'mobile number';
export const EORI = 'EORI';
export const GB = 'GB';
export const IE = 'IE';

// PAYMENT
export const PURCHASE_WIDGET = {
  CHECKOUT: 'PROCEED TO CHECKOUT',
  CLOSE: 'CLOSE',
  SUBMIT: 'Submit',
  WIDGET_CREATE_ERROR: 'Payment Widget Create Error',
  CLIENT_TOKEN_ERROR: 'Get Client Token Error',
  TOKENIZATION_ERROR: 'Tokenization Error',
  PAYMENT_ERROR: 'Payment Error',
  LIABILITY_ERROR: 'Liability Did Not Shift',
  PAYMENT_CURRENCY_CODE: 'GBP',
  MERCHANT_NAME: 'DPD group UK',
  MERCHANT_ID: 'YourDPD',
  TOTAL_PRICE_STATUS: 'FINAL',
  ALLOWED_PAYMENT_METHOD_TYPE: 'CARD',
  BILLING_ADDRESS_PARAMETERS_FORMAT: 'FULL',
};

export const VIEW_CART = 'VIEW CART';

// SUMMARY DETAILS
export const SUMMARY_DETAILS = {
  TITLE: 'Your Summary',
  STANDARD_DELIVERY: 'Standard next day delivery',
  COLLECT_PARCEL: 'Collect my parcel',
  DELIVER_RECIPIENT: 'Deliver to the recipient',
  INSURANCE_COVER: 'Insurance cover',
  TOTAL: 'Total',
  INCLUDES_VAT_$: vatAmount => `Includes VAT of £${vatAmount}`,
};

// SHIPPING DETAILS
export const SHIPPING_DETAILS = {
  TITLE: 'Shipping details',
  SENDER: 'Sender',
  RECEIVER: 'Receiver',
};

// SALES CONDITIONS
export const SALES_CONDITIONS = {
  TITLE: 'Sales conditions',
  ACCEPT_THE: 'I accept the ',
  CONFIRM_THAT_MY_SHIPMENT: 'I confirm that my shipment doesn’t contain any ',
  TERMS_AND_CONDITIONS: 'Terms & Conditions',
  PROHIBITED_ITEMS: 'prohibited items',
  INFORMATION_COLLECTING:
    'The information collected on this form is processed by DPDgroup UK for the delivery of your parcel.',
  MORE_ABOUT_DATA_MANAGEMENT:
    'Find out more about the management of your data.',
  I_WOULD_LIKE_TO_RECEIVE:
    'I would like to receive special offers and discounts via email from DPD',
  PROHIBITED_ITEMS_DESCRIPTION:
    'The following items (or any item similar in description or content) can not be carried on any service.',
  ORDER_CANCELLATION_WARNING:
    'Any person sending such an item may be subject to their order being cancelled without notice:',
  SPECIAL_PROVISIONS: 'Special Provisions',
  DUTY_AND_TAX_INFO: {
    TITLE: 'Important Duty and Tax Information',
    SHIPPING_TO_COUNTRY_$: destinationCountry =>
      `When shipping to ${destinationCountry}, your recipient may be required to pay import duties and taxes before the parcel is released for delivery. This is a standard procedure for international shipments.`,
    DESCRIPTION: `Please note: If you're using the Import One-Stop Shop (IOSS) system, these charges will have already been collected and paid by the marketplace, and your recipient won't need to pay anything additional.`,
    LINK: 'For more information on import duties and taxes, please visit',
  },
};

// PRODUCTS & SERVICES
export const PRODUCT_AND_SERVICES = {
  MAIN_OVERLINE_HEADER: 'July 2024 | International Services',
  UK_TO_FRANCE_TITLE: 'UK to France? Choose DPD!',
  PRICE_START_FROM: `Prices start from £{0} exc VAT`,
  THE_CHOICE_IS_YOURS:
    'The choice is yours! Schedule a home collection or drop off at any of our 10,000+ DPD Pickup locations.',
  LISTS_WITH_TITLES: [
    {
      TITLE: 'UK to France? Choose DPD!',
      LIST: [
        'Stress-free shipping: Our user-friendly website lets you compare express and economy options in seconds. Find the perfect balance for your budget and speed needs.',
        "Trusted by millions: We're a leading choice for parcel delivery across Europe.",
        'Nationwide coverage: Send your parcel anywhere in France, from bustling Paris to the sunny southern coast.',
        'Get a quote: The exact cost depends on size, weight, and service, but we offer competitive rates for all options.',
      ],
    },
    {
      TITLE: "Sending to France? Here's what you need to know about customs:",
      LIST: [
        'Gifts under £39/€45 (including shipping) are VAT-free.',
        'Parcels over £39 or from online marketplaces incur a 20% EU VAT.',
        'Reduced VAT rates (10% or 5.5%) may apply for specific items.',
        'For details on reduced VAT rates, please visit the French customs website.',
      ],
    },
    {
      TITLE: 'Smooth Sailing Through French Customs:',
      LIST: [
        'Know Your Stuff: During booking, you might be asked for a commodity code (HS/Tariff code). This helps customs identify your items for safety, legality, and potential duties.',
        "Finding Your Code: We'll guide you through finding the right code during the booking process.",
        'Need Help? Unsure about customs information? Check our detailed advice page or contact our friendly team for any questions.',
      ],
    },
  ],
  MEDIA_CARD: {
    EXC_VAT: 'exc VAT',
    INC_VAT: '£{0} inc VAT (parcels up to 20kg)',
    CHOOSE_THIS_SERVICE: 'Choose this service',
  },
  HELPFUL_DETAILS: {
    TITLE: 'Here are some additional details you might find helpful:',
    DETAILS: [
      {
        TITLE: 'How fast can I get a parcel to France?',
        DESCRIPTION:
          'Fast & affordable France delivery! Standard options reach France in 3-4 days, or choose express delivery for super-speedy arrivals in 2 days.',
      },
      {
        TITLE:
          "Don't sweat the French address! Check out our guide for hassle-free parcel delivery.",
        DESCRIPTION:
          'France or UK? Addressing is almost the same! Get it right with our guide.',
      },
      {
        TITLE:
          'Track your DPD parcel in France! Enter your tracking number for real-time updates.',
        DESCRIPTION:
          'Say goodbye to delivery guesswork! DPD Predict for France provides the receiver with a one-hour delivery window for a stress-free experience.',
      },
      {
        TITLE:
          'Check before you ship to France: See a list of restricted items',
        DESCRIPTION:
          'Planning your France shipment? We can help! Here are some items that require extra attention:\n' +
          '\n' +
          'Active mobile phones from outside the EU: These may require additional processing.\n' +
          'Batteries: Check regulations for specific types.\n' +
          'Credit/debit/cash cards: Not recommended for international shipping due to security concerns.\n' +
          'Fishing rods: May require special declarations.\n' +
          'Perishable goods: Generally not allowed due to customs regulations.\n' +
          '\n' +
          'For a complete list and regulations, please see:  Our prohibited items page  or HM Revenue and Customs.',
      },
    ],
  },
};

// ADDRESS DETAILS
export const ADDRESS_DETAILS = {
  DETAILS_TITLE: 'Your details',
  CUSTOMS_DETAILS: 'Customs details',
  COMPANY_NAME: 'Company name',
  CONTACT_NAME: 'Your full name',
  EMAIL: 'Your email address',
  EMAIL_CONFIRM: 'Confirm email address',
  PHONE: 'Your phone number',
  SAVE_FOR_NEXT_TIME: 'Save this information for next time',
  RECEIVER_DETAILS_TITLE: 'Receivers details',
  RECEIVER_CONTACT_NAME: 'Receiver’s full name',
  RECEIVER_EMAIL: 'Receivers email address',
  RECEIVER_PHONE: 'Receivers phone number',
};

export const ADDRESS_WIDGET = {
  EDIT: 'Edit Address',
};

export const ADDRESS_LOOKUP = {
  LOOKUP_PLACEHOLDER: 'Start entering your address',
  LABEL: 'Address Search',
  LOOKUP_ERROR: 'Address lookup error',
};

export const AT_RISK_TITLE =
  "Are the items in your parcel 'at risk' of onward movement to or sale to businesses or consumers in the European Union (EU)?";
export const AT_RISK_TEXT =
  "When shipping goods to Northern Ireland, a declaration is required. DPD will make the declaration on your behalf but in order to do so you will need to inform us whether the goods shipped will remain in Northern Ireland and are 'not at risk'  or if they are  'at risk' to be sold or sent onward to the EU. DPD will need to complete different declaration processes in each scenario. If you are unsure select 'yes' to ensure your parcel is handled correctly and compliantly.";

// ADDRESS BOOK
export const ADDRESS_BOOK_MODAL = {
  TITLE: 'Address Book',
};
export const SELECT_ADDRESS = 'Select';

export const PICKUP_MODAL = {
  TITLE: 'Find your nearest Pickup Point',
  LOOKUP_PLACEHOLDER: 'Start typing an address',
  LOOKUP_ERROR: 'Address lookup error',
};

// USER PREFERENCES
export const USER_PREFERENCES_SECTIONS = {
  OVERVIEW: 'Overview',
  ACCOUNT: 'Account',
  ADDRESSES: 'Addresses',
  ORDERS: 'Orders',
};

export const COMMODITY_FINDER = {
  TITLE: 'Commodity code finder',
  SUBTITLE: 'e.g. Leather Shoes, Toys, Women’s Blouse',
  SEARCH_BUTTON: 'Search',
  NO_RESULT: {
    TITLE: 'No search results found',
    SUBTITLE: 'Sorry, but nothing matched your search criteria.',
    DESCRIPTION: 'Please try again with different or less specific keywords.',
  },
};

export const ADDRESSES_SECTION = {
  SECTION_TITLE: 'Your Addresses',
  NO_ADDRESSES_ADDED: "You haven't added any addresses",
  NO_ADDRESSES_FOUND: 'Sorry, there were no results for your query',
  BULK_UPLOAD: 'Bulk Upload',
  ADD_NEW_ADDRESS: 'Add new address',
  TABLE_ACTIONS: {
    EDIT: 'View / Edit',
    DELETE: 'Delete',
  },
  SIDEBAR_FORM: {
    ADD_NEW_ADDRESS: 'Add New Address',
    EDIT_ADDRESS: 'Edit Address',
    CANCEL: 'Cancel',
    SAVE: 'Save',
  },
  //TODO: agree notification messages
  NOTIFICATIONS: {
    SUCCESS: {
      CREATE: 'Address added',
      UPDATE: 'Address updated',
      DELETE: 'Address deleted',
      BULK_UPLOAD: 'Bulk upload complete',
    },
    ERROR: {
      CREATE: 'Error during creation',
      UPDATE: 'Error during update',
      DELETE: 'Error during deletion',
      RETRIEVE: 'Something went wrong. Please try again later',
      DOWNLOAD_TEMPLATE:
        'Error during template downloading. Please try again later',
      FETCHING_ERROR: 'Error while loading address book',
    },
  },
  DELETE_MODAL: {
    TITLE: 'Confirm Deletion',
    TEXT_$: WANT_TO_REMOVE_$,
    CANCEL_BUTTON: 'Cancel',
    DELETE_BUTTON: 'Delete',
  },
  BULK_UPLOAD_MODAL: {
    TITLE: 'Bulk upload',
    DOWNLOAD_TEMPLATE_CSV_TITLE: 'Download a Template (CSV)',
    DOWNLOAD_TEMPLATE_CSV_TEXT:
      'Click the button below to download a sample CSV file formatted for uploading addresses. This file includes column headers to guide you on filling in your specific address data.',
    UPLOAD_TEMPLATE_CSV_TITLE: 'Upload Your CSV',
    UPLOAD_TEMPLATE_CSV_TEXT:
      'Once you\'ve filled out the template with your addresses, click "Choose File" to select your CSV file and upload it here.',
    LOADING: 'Upload in progress...',
    ERROR: 'Something went wrong',
    CANCEL_ERROR: 'Bulk upload was canceled',
    BUTTONS: {
      DOWNLOAD_TEMPLATE: 'Download template',
      CHOOSE_FILE: 'Choose file',
      CANCEL: 'Cancel',
      TRY_AGAIN: 'Try again',
    },
  },
  SEARCH: {
    BUTTON: 'Search',
    LABEL: 'Search address book',
  },
};

export const ORDERS_SECTION = {
  SECTION_TITLE: 'Your Orders',
  NO_ORDERS_ADDED: 'No orders to show',
  TABLE_ACTIONS: {
    VIEW_ORDER: 'View Order',
    SEND_AGAIN: 'Send Again',
    PRINT_INVOICE: 'Print Invoice',
    CONTACT_US: 'Contact Us',
  },
  NOTIFICATIONS: {
    COPY_TO_CLIPBOARD_SUCCESS: 'Parcel number copied to clipboard',
    FETCHING_ERROR: 'Error while loading orders',
    DUPLICATE_ORDER_ERROR: 'Error while duplicating order',
  },
  ERROR: 'Something went wrong',
};

export const ORDER_DETAILS = {
  RECIPIENT_ADDRESS: 'Recipient Address',
  PICKUP_ADDRESS: 'Pickup Address',
  HISTORY: 'History',
  DELIVERING_IN_AN_ALL_ELECTRIC_VEHICLE:
    'Proudly delivering in an all-electric vehicle',
  UNKNOWN: 'Unknown',
  GALLERY_TITLE: 'Photo on delivery images',
  issueDelayDaysMessage_$: (issueDelayDays, issueName) =>
    `There is currently a ${issueDelayDays} day delay in your area due to ${issueName}.`,
  issueDelayMessage_$: issueName =>
    `There are currently delays in your area due to ${issueName}.`,
  DOWNLOAD: 'Download',
  PDF: 'PDF',
  CSV: 'CSV',
  SEND_AGAIN: 'Send Again',
  BACK_TO_ORDERS: 'Back to orders',
};

export const FILE_FORMAT = {
  PDF: 'pdf',
  CSV: 'csv',
};

export const DELIVERY_PREFERENCES = 'Delivery Preferences';

export const AUTH = {
  SIGNOUT_ERROR: 'Sign Out error',
  SEND_MAGIC_LINK_ERROR: 'Send Magic Link error',
};

const AUTH_RETRY_AFTER_TITLE =
  'Sorry, you’ve made too many attempts to sign in';
const AUTH_RETRY_AFTER_DESCRIPTION_$ = time =>
  `It looks like you've tried to sign in too many times. Please try again ${
    time ? `in ${time}` : 'later'
  }.`;
export const TOO_MANY_REQUESTS_MESSAGE = time =>
  `Too many requests. Please try again ${time ? `in ${time}` : 'later'}`;

export const ERROR_MESSAGES_MAP = {
  [REACH_MAX_LOGIN_ATTEMPTS_CODE]: {
    title: AUTH_RETRY_AFTER_TITLE,
    description: AUTH_RETRY_AFTER_DESCRIPTION_$,
  },
  [AUTH_TOO_MANY_REQUESTS]: {
    title: AUTH_RETRY_AFTER_TITLE,
    description: AUTH_RETRY_AFTER_DESCRIPTION_$,
  },
  default: {
    title: 'An unknown error occurred',
  },
};

export const ACCOUNT_DETAILS_SECTION = {
  ACCOUNT_DETAILS: 'Account Details',
  SNACKBAR_MESSAGES: {
    SUCCESS: 'Preference saved',
    FAILED: 'Preference failed to save. Please try again',
  },
  SAVE: 'Save',
};

export const COMMUNICATION_PREFERENCES_SECTION = {
  COMMUNICATION_PREFERENCES: 'Communication Preferences',
  MARKETING_COMMUNICATION: {
    TITLE: 'Marketing Communications',
    DESCRIPTION:
      'Be the first to know about exclusive deals and limited-time offers. Plus, get valuable marketing tips to get the most out of our products and services.',
  },
  TRACKING_UPDATES: {
    TITLE: 'Tracking Updates',
    DESCRIPTION:
      "Stay in the loop! We'll keep you updated on your order with clear tracking information, delivery notifications, and any relevant account updates.",
  },
  SNACKBAR_MESSAGES: {
    SUCCESS: 'Communication preference saved',
    FAILED: 'Communication preference failed to save. Please try again',
  },
};

export const DELETE_ACCOUNT_SECTION = {
  DELETE_ACCOUNT: 'Delete Account',
  WE_UNDERSTAND_WARNING:
    'We understand you might not need your DPD Online account anymore. Deleting your account will permanently remove your profile and past order history, along with any pre-pay balance (no refunds after deletion).',
  ONCE_DELETED_WARNING:
    'Once deleted, you won\'t be able to recover your account or information. Click "Delete My Account" to confirm. This action is permanent.',
  DELETE_MY_ACCOUNT: 'Delete my account',
  DELETE_ACCOUNT_MODAL: {
    CONFIRM_DELETION: 'Confirm Deletion',
    DELETION_WARNING:
      "Once deleted, you won't be able to recover your account or information.",
    CANCEL: 'Cancel',
    DELETE: 'Delete',
  },
};

export const OVERVIEW_SECTION = {
  GREETINGS_$: name => `Hi ${name}`,
  TRACK_PARCELS:
    'Easily track your parcels in real-time, view past orders and manage saved addresses for quick rebooking.',
  YOUR_RECENT_ORDERS: 'Your Recent Orders',
  VIEW_ALL: 'VIEW ALL',
  YOUR_ADDRESSES: 'Your Addresses',
  ACCOUNT_SUMMARY: 'Account Summary',
  MANAGE_ACCOUNT: 'MANAGE ACCOUNT',
  NAME: 'Name',
  EMAIL_ADDRESS: 'Email Address',
  PHONE_NUMBER: 'Phone Number',
};

export const CONFIRMATION = {
  TITLE:
    'Show this QR code when you drop off your parcel at a DPD Pickup point.',
  THANK_YOU: 'Thank you',
  ALL_DONE: 'you’re all done.',
  HOME_COLLECTION_INSTRUCTIONS: 'Home collection instructions',
  SHARE_PARCEL_INFO: 'Share parcel info',
  DOWNLOAD_INVOICE: 'Download invoice',
  DOWNLOAD_LABEL_TO_PRINT: 'Download label to print',
  DOWNLOAD_LABEL_AS_PDF: 'Download label as PDF',
  SEND_ANOTHER_PARCEL: 'Send another parcel',
  PARCEL_PASS_LABEL: 'Parcel Pass/Label',
  SHARE_MODAL_TITLE: 'Share parcel info',
  COPY_LINK: 'Copy link',
  DOWNLOAD_ERROR: 'Failed to download label:',
  ERROR: 'Something went wrong',
  VIEW_QR_CODE: 'VIEW QR CODE',
  VIEW_LABEL: 'VIEW LABEL',
  STEPS: {
    PACK_ITEM: 'Pack your item to avoid any transport damage.',
    PRINT_LABEL:
      'Print your label and stick it on the parcel. Make sure to cover other labels on the package.',
    BRING_PARCEL_TO_DROPOFF: 'Bring the parcel to a drop-off of your choice.',
    DRIVER_COLLECT:
      'The driver will collect your parcel on your selected day between 8:00 and 17:00.',
  },
  INTERNATIONAL: {
    INFO_TITLE: 'Important note',
    INFO_DESCRIPTION:
      'For a shipment abroad, pro-forma invoice in triplicate must be attached to the parcel. Each copy must be signed in the original. The maximum value of good is CHF 150.-',
    CREATE_PROFORMA_INVOICE: 'Create pro-forma invoice',
  },
};

export const PARCEL_INSURANCE = {
  TITLE: 'Parcel insurance',
  DESCRIPTION: (additionalProtectionPrice, coveredValue) => {
    if (coveredValue) {
      return `The value of you parcel is covered up to £${Number(
        coveredValue
      ).toFixed(2)} amount`;
    } else if (additionalProtectionPrice) {
      return `The full value of you parcel is not covered. Would you like to add additional protection for £${Number(
        additionalProtectionPrice
      ).toFixed(2)}?`;
    } else {
      return 'The full value of you parcel is covered.';
    }
  },
  ADD_PROTECTION: 'Add protection',
  REMOVE_PROTECTION: 'Remove protection',
};

export const ADD_TO_WALLET = {
  GOOGLE: 'ADD TO GOOGLE WALLET',
  APPLE: 'ADD TO APPLE WALLET',
};

export const OS = {
  IOS: 'iOS',
  ANDROID: 'Android',
};

export const COPIED_TO_CLIPBOARD = 'Copied to clipboard';

export const DISMISS = 'Dismiss';
