import Async from 'react-async';
import {
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../ErrorBoundary';
import Pending from './Pending';

const Loader = ({
  children,
  promiseFn,
  errorComponent,
  pendingProps,
  ...args
}) => {
  const { loadingCrash } = useErrorBoundaryConfigurations();
  return (
    <Async promiseFn={promiseFn} {...args}>
      <Async.Pending>
        <Pending {...pendingProps} />
      </Async.Pending>
      <Async.Fulfilled>{children}</Async.Fulfilled>
      <Async.Rejected>
        {error =>
          errorComponent ? (
            errorComponent(error)
          ) : (
            <ErrorBoundary config={loadingCrash(error.message)} />
          )
        }
      </Async.Rejected>
    </Async>
  );
};

Loader.propTypes = Async.propTypes;

export default Loader;
