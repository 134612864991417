import PropTypes from 'prop-types';
import { Grid, Typography, useTheme } from '@mui/material';
import AddressView from '../MapBox/components/AddressView';
import PickupPointImage from '../../assets/images/pickup_point.png';
import { getPartnerLogo, getPickupAddress } from '../../utils/pickupPoint';

const PickupPointWidget = ({
  pickupPoint,
  pickupPointLabel,
  showAddressInLine,
}) => {
  const theme = useTheme();
  const partnerLogo = getPartnerLogo(pickupPoint);
  const address = getPickupAddress(pickupPoint);

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      columnSpacing={2}
    >
      <Grid
        item
        xs={4}
        md={6}
        justifyContent='center'
        alignItems='center'
        textAlign='center'
        sx={{
          overflow: 'hidden',
        }}
      >
        <img
          src={partnerLogo || PickupPointImage}
          alt='Pickup Point'
          style={{
            height: 'auto',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.primary.borderLight}`,
            maxHeight: !partnerLogo ? '120px' : 'none',
          }}
        />
      </Grid>
      <Grid item xs={8} md={6}>
        <Typography
          sx={
            showAddressInLine && {
              fontSize: '15px',
              fontWeight: 'bold',
              wordWrap: 'break-word',
            }
          }
        >
          {pickupPointLabel}
        </Typography>
        <AddressView
          address={address}
          inline={showAddressInLine}
          pickupPoint={pickupPoint}
          titleWidth='100%'
          boxWidth='100%'
        />
      </Grid>
    </Grid>
  );
};

PickupPointWidget.propTypes = {
  partnerLogo: PropTypes.string,
  pickupPoint: PropTypes.object.isRequired,
  pickupPointLabel: PropTypes.string,
};

export default PickupPointWidget;
