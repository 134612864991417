import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Badge, Menu, MenuItem } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import AppsIcon from '@mui/icons-material/Apps';
import { useSelector } from 'react-redux';
import { DPD_ONLINE } from '../../constants/strings';
import { PrimaryCube } from '../icons';
import { useAuth } from '../../features/Auth/Context';
import { MAIN_MENU } from '../../constants/analytics';
import useAnalytics from '../../utils/useAnalytics';
import {
  BASKET,
  PICKUP_FINDER,
  PROFILE,
  QUOTES,
  RETURN,
  SIGN_IN_WITH_EMAIL,
  TRACK,
} from '../../constants/routes';
import { BasketSelectors } from '../../redux/basket';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.vars.palette.text.primary,
  position: 'relative',
  zIndex: 3,
}));

const StyledLogoText = styled(Typography)(({ theme }) => ({
  textTransform: 'none',
  marginLeft: 16,
  color: theme.vars.palette.text.primary,
}));

const StyledNavigationButton = styled(Button)(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : 'inherit',
}));

const HREF_ATTRIBUTE = 'href';

function HomeAppBar() {
  const { currentSession, signOut } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const count = useSelector(BasketSelectors.getBasketItemsCount);
  const { trackAction, Trackable } = useAnalytics();

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onMenuItemClick = useCallback(
    event => {
      event.preventDefault();
      handleCloseNavMenu();
      trackAction(event);
      const href = event.currentTarget.getAttribute(HREF_ATTRIBUTE);
      if (href) {
        navigate(href);
      }
    },
    [navigate, trackAction]
  );

  const onSignOutClick = useCallback(
    async event => {
      event.preventDefault();
      handleCloseNavMenu();
      trackAction(event);

      // Signout
      signOut();
    },
    [signOut, trackAction]
  );

  return (
    <Trackable loadId={MAIN_MENU.LOAD}>
      <Box>
        <StyledAppBar position='static'>
          <Toolbar>
            <Box sx={{ flexGrow: { xs: 1, md: 0 } }}>
              <Button href='/' onClick={onMenuItemClick}>
                <PrimaryCube />
                <StyledLogoText noWrap>{DPD_ONLINE}</StyledLogoText>
              </Button>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                alignItems: 'center',
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
              }}
            >
              <StyledNavigationButton
                active={pathname === QUOTES}
                actionid={MAIN_MENU.CLICK_SEND}
                href={QUOTES}
                onClick={onMenuItemClick}
              >
                Send
              </StyledNavigationButton>
              <StyledNavigationButton
                active={pathname === TRACK}
                actionid={MAIN_MENU.CLICK_TRACK}
                href={TRACK}
                onClick={onMenuItemClick}
              >
                Track
              </StyledNavigationButton>
              <StyledNavigationButton
                active={pathname === RETURN}
                actionid={MAIN_MENU.CLICK_RETURN}
                href={RETURN}
                onClick={onMenuItemClick}
              >
                Return
              </StyledNavigationButton>
              <StyledNavigationButton
                active={pathname === PICKUP_FINDER}
                actionid={MAIN_MENU.CLICK_PICKUPFINDER}
                href={PICKUP_FINDER}
                onClick={onMenuItemClick}
              >
                Pickup Finder
              </StyledNavigationButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'block' } }}>
              <IconButton
                size='large'
                aria-label='home'
                color='inherit'
                href='/'
                sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                onClick={onMenuItemClick}
              >
                <HomeIcon />
              </IconButton>
              <IconButton
                size='large'
                aria-label='basket'
                color='inherit'
                href={BASKET}
                actionid={MAIN_MENU.CLICK_BASKET}
                onClick={onMenuItemClick}
              >
                <Badge badgeContent={count} color='error'>
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
              {!currentSession?.uid && (
                <Button
                  color='inherit'
                  href={SIGN_IN_WITH_EMAIL}
                  actionid={MAIN_MENU.CLICK_SIGN_IN}
                  onClick={onMenuItemClick}
                >
                  Sign In
                </Button>
              )}
              {currentSession?.uid && (
                <>
                  <IconButton
                    size='large'
                    aria-label='user preferences'
                    color='inherit'
                    href={PROFILE}
                    actionid={MAIN_MENU.CLICK_ACCOUNT}
                    onClick={onMenuItemClick}
                  >
                    <PersonIcon />
                  </IconButton>
                  <IconButton
                    size='large'
                    aria-label='sign out'
                    actionid={MAIN_MENU.CLICK_SIGN_OUT}
                    onClick={onSignOutClick}
                    sx={{
                      display: { xs: 'none', md: 'inline-flex' },
                    }}
                  >
                    <LogoutIcon />
                  </IconButton>
                </>
              )}
              <Box
                sx={{ flexGrow: 1, display: { xs: 'inline-flex', md: 'none' } }}
              >
                <IconButton
                  size='large'
                  aria-label='home'
                  color='inherit'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleOpenNavMenu}
                >
                  <AppsIcon />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  <MenuItem
                    key='send'
                    href={QUOTES}
                    actionid={MAIN_MENU.CLICK_SEND}
                    onClick={onMenuItemClick}
                  >
                    <Typography textAlign='center'>Send</Typography>
                  </MenuItem>
                  <MenuItem
                    key='track'
                    actionid={MAIN_MENU.CLICK_TRACK}
                    href={TRACK}
                    onClick={onMenuItemClick}
                  >
                    <Typography textAlign='center'>Track</Typography>
                  </MenuItem>
                  <MenuItem
                    key='return'
                    actionid={MAIN_MENU.CLICK_RETURN}
                    href={RETURN}
                    onClick={onMenuItemClick}
                  >
                    <Typography textAlign='center'>Return</Typography>
                  </MenuItem>
                  <MenuItem
                    key='pickupfinder'
                    actionid={MAIN_MENU.CLICK_PICKUPFINDER}
                    href={PICKUP_FINDER}
                    onClick={onMenuItemClick}
                  >
                    <Typography textAlign='center'>Pickup Finder</Typography>
                  </MenuItem>
                  {currentSession?.uid && (
                    <MenuItem
                      key='signout'
                      aria-label='sign out'
                      actionid={MAIN_MENU.CLICK_SIGN_OUT}
                      onClick={onSignOutClick}
                    >
                      <Typography textAlign='center'>Sign Out</Typography>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </StyledAppBar>
      </Box>
    </Trackable>
  );
}

export default HomeAppBar;
