import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Button, Grid, Typography, useTheme } from '@mui/material';

import { ordersApis } from '../../apis';
import Loader from '../../features/Loader';
import {
  ORDER_SUMMARY,
  VIEW_YOUR_ORDERS,
  VIEW_INSTRUCTIONS,
} from '../../constants/strings';
import {
  BasketCardHeader,
  DefaultBasketCard,
} from '../../components/BasketCard';
import {
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../../features/ErrorBoundary';
import BasketItemsGroupsList from '../../components/BasketItemsGroupsList';

const OrderSummary = () => {
  const { orderId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { orderError } = useErrorBoundaryConfigurations();

  const loadData = useCallback(async ({ orderId }) => {
    const { data } = await ordersApis.getOrderById(orderId);
    return data.shipments;
  }, []);

  const ViewInstructionsButton = useCallback(({ shipment }) => {
    const handleNavigate = () => {
      navigate(`/shipments/${shipment.shipmentId}`, {
        state: { fromOrderDetails: true },
      });
    };

    return (
      <Button
        onClick={handleNavigate}
        variant='contained'
        sx={{ width: '175px' }}
      >
        {VIEW_INSTRUCTIONS}
      </Button>
    );
  }, []);

  return (
    <Loader
      promiseFn={loadData}
      orderId={orderId}
      errorComponent={error => (
        <ErrorBoundary config={orderError(error.message)} />
      )}
    >
      {shipments => (
        <Grid
          container
          sx={{
            background: theme.palette.primary.pageBackground,
            flexGrow: 1,
            justifyContent: 'center',
            pb: 6,
          }}
        >
          <Grid
            xs={12}
            md={6}
            sx={{
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='h2'
              textAlign='center'
              color='primary.main'
              sx={{ py: 3 }}
            >
              {ORDER_SUMMARY}
            </Typography>
            <Typography variant='h3' textAlign='center'>
              {VIEW_YOUR_ORDERS}
            </Typography>

            <BasketItemsGroupsList
              basketItems={shipments.map(shipment => ({
                shipment,
              }))}
              basketItemComponent={({ basketItem: { shipment } }) => (
                <DefaultBasketCard
                  outboundConsignment={shipment?.outboundConsignment}
                  containerStyles={{ mt: 2 }}
                  contentContainerStyles={{ p: 2, py: 3 }}
                  header={
                    <BasketCardHeader
                      shipment={shipment}
                      rightCardContent={
                        <ViewInstructionsButton shipment={shipment} />
                      }
                    />
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};

export default OrderSummary;
