import { createSelector } from '@reduxjs/toolkit';

import { OrderSliceSelectors } from '../../slice';
import { ExportReasonsSelectors } from '../../../../redux/exportReasonSlice';
import { InvoiceHelpers } from '../../../../helpers';
import {
  getActivePickupPoints,
  getAllowedFields,
  getInitialValues,
  getMandatoryFields,
  getRequiredKeys,
} from './helpers';

export const getInitialFormValues = createSelector(
  OrderSliceSelectors.getValues,
  getInitialValues
);

export const getRequiredFields = createSelector(
  OrderSliceSelectors.getValues,
  getMandatoryFields
);

export const getRequiredData = createSelector(
  getRequiredFields,
  requiredFields => ({
    requiredFields,
    requiredKeys: getRequiredKeys(requiredFields),
  })
);

export const getAvailableFields = createSelector(
  [
    OrderSliceSelectors.getValues,
    OrderSliceSelectors.getReferences,
    (state, currentSession) => currentSession,
  ],
  getAllowedFields
);

export const getPickupPoints = createSelector(
  OrderSliceSelectors.getReferences,
  getActivePickupPoints
);

export const getExportReasonsKeyValue = createSelector(
  ExportReasonsSelectors.getExportReasons,
  OrderSliceSelectors.getValues,
  InvoiceHelpers.filterExportReasons
);
