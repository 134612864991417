import { filter, get, isBoolean } from 'lodash';
import moment from 'moment-timezone';
import { CURRENCY_SYMBOLS } from '../constants';
import { DATE_FORMAT } from '../../../constants';
import { DateUtil } from '../../../utils';

export const getDefaultIsBusiness = (
  shipment,
  { businessPath, organisationPath }
) => {
  let defaultValue = get(shipment, businessPath);

  if (isBoolean(defaultValue)) {
    return defaultValue;
  }

  const organisation = get(shipment, organisationPath);

  return !!organisation;
};

export const getCutOffDates = dates =>
  filter(dates, date =>
    DateUtil.getUKCurrentMomentTime().isBefore(
      moment.tz(
        `${date.date} ${date.collectionCutOff}`,
        `${DATE_FORMAT.DEFAULT_DATE_FORMAT} ${DATE_FORMAT.HH_MM_TIME_FORMAT}`,
        DATE_FORMAT.EUROPE_LONDON_TIME_ZONE
      )
    )
  );

export const formatShipmentDate = ({ date, collectionCutOff }) =>
  moment
    .tz(
      `${date} ${collectionCutOff}`,
      `${DATE_FORMAT.DEFAULT_DATE_FORMAT} ${DATE_FORMAT.HH_MM_TIME_FORMAT}`,
      DATE_FORMAT.EUROPE_LONDON_TIME_ZONE
    )
    .format(DATE_FORMAT.DATE_TIME_FORMAT);

export const getDefaultShipmentDate = defaultCutOff => {
  const nextWorkingDay = DateUtil.getNextWorkingDay();
  return formatShipmentDate({
    date: nextWorkingDay.format(DATE_FORMAT.DEFAULT_DATE_FORMAT),
    collectionCutOff: defaultCutOff,
  });
};

export const getCurrencySymbol = (value = CURRENCY_SYMBOLS.GBP) =>
  CURRENCY_SYMBOLS[value];
