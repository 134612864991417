import { Grid, Divider, Paper, Collapse } from '@mui/material';
import {
  CHOSEN_PICKUP_POINT,
  DROP_OFF_POINT,
  SHIPPING_DETAILS,
} from '../../constants/strings';
import PickupPointWidget from '../PickupPointwidget/index';
import { BasketCardContent } from './index';

const getBasketCardData = ({
  data = {},
  collectCountryName,
  deliveryCountryName,
}) => {
  const { collectionDetails = {}, deliveryDetails = {} } = data;

  const getCommonData = ({ address = {} }) => ({
    county: address.county,
    locality: address.locality,
    organisation: address.organisation,
    postcode: address.postcode,
    street: address.street,
    town: address.town,
  });

  return {
    collectionData: {
      ...getCommonData(collectionDetails),
      contactName: collectionDetails.contactDetails?.contactName,
      email: collectionDetails.contactDetails?.email,
      telephone: collectionDetails.contactDetails?.telephone,
      countryName: collectCountryName,
    },
    deliveryData: {
      ...getCommonData(deliveryDetails),
      contactName: deliveryDetails.contactDetails?.contactName,
      email: deliveryDetails.notificationDetails?.email,
      telephone:
        deliveryDetails.notificationDetails?.mobile ||
        deliveryDetails.contactDetails?.telephone,
      countryName: deliveryCountryName,
    },
  };
};

export const DefaultBasketCard = ({
  header,
  references = {},
  footer,
  Container = Paper,
  contentContainerStyles,
  containerStyles,
  isContentOpened = true,
  outboundConsignment,
  collectionCountryName,
  deliveryCountryName,
}) => {
  const { collectionData, deliveryData } = getBasketCardData({
    data: outboundConsignment,
    collectionCountryName,
    deliveryCountryName,
  });
  return (
    <Container sx={containerStyles} data-testid={'basket_item'}>
      {header}
      <Collapse in={isContentOpened}>
        <Grid container sx={contentContainerStyles}>
          <Grid sx={{ mb: 2, pr: { md: 1 }, order: { md: 1 } }} xs={12} md={6}>
            <BasketCardContent
              data={collectionData}
              title={SHIPPING_DETAILS.SENDER}
            />
          </Grid>
          <Grid
            sx={{ mb: { xs: 2, md: 0 }, pr: { md: 1 }, order: { md: 3 } }}
            xs={12}
            md={6}
          >
            {references.collectionPickup && (
              <PickupPointWidget
                pickupPoint={references.collectionPickup}
                pickupPointLabel={DROP_OFF_POINT}
                showAddressInLine
              />
            )}
          </Grid>
          <Grid sx={{ mb: 2, pl: { md: 1 }, order: { md: 2 } }} xs={12} md={6}>
            <BasketCardContent
              data={deliveryData}
              title={SHIPPING_DETAILS.RECEIVER}
            />
          </Grid>
          <Grid sx={{ pl: { md: 1 }, order: { md: 4 } }} xs={12} md={6}>
            {references.deliveryPickup && (
              <PickupPointWidget
                pickupPoint={references.deliveryPickup}
                pickupPointLabel={CHOSEN_PICKUP_POINT}
                showAddressInLine
              />
            )}
          </Grid>
        </Grid>
      </Collapse>
      {footer && (
        <>
          <Divider />
          {footer}
        </>
      )}
    </Container>
  );
};
