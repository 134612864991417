import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@emotion/react';
import { FILE_FORMAT, ORDERS_SECTION } from '../../../../constants/strings';

import useBreakpoint from '../../../../hooks/useBreakpoint';
import { normalizeParcelNumber } from '../../../../utils/parcel';
import { BasketActions } from '../../../../redux/basket';
import { useOverlay } from '../../../../features/Overlay';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import { getOrderInvoiceDownloadUrl } from '../utils/downloadUrl';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  minHeight: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  paddingTop: '6px',
  paddingBottom: '6px',
  wordBreak: 'break-word',
  minWidth: '122px',
  '&:first-child': {
    minWidth: '175px',
  },
  '&:last-child': {
    minWidth: '40px',
  },
}));

const StyledBox = styled(Box)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '360px',
}));

const ActionMenu = ({
  actionMenuAnchorEl,
  actionMenuBtnHandleClose,
  handleViewParcel,
  handleSendAgainOrder,
  handlePrintInvoice,
}) => (
  <Menu
    anchorEl={actionMenuAnchorEl}
    open={!!actionMenuAnchorEl}
    onClose={actionMenuBtnHandleClose}
    MenuListProps={{
      sx: { width: '124px' },
    }}
    anchorOrigin={{
      horizontal: 'left',
      vertical: 'bottom',
    }}
    transformOrigin={{
      horizontal: 120,
      vertical: 90,
    }}
  >
    <StyledMenuItem disableRipple onClick={handleViewParcel}>
      {ORDERS_SECTION.TABLE_ACTIONS.VIEW_ORDER}
    </StyledMenuItem>
    <StyledMenuItem disableRipple onClick={handleSendAgainOrder}>
      {ORDERS_SECTION.TABLE_ACTIONS.SEND_AGAIN}
    </StyledMenuItem>
    <StyledMenuItem disableRipple onClick={handlePrintInvoice}>
      {ORDERS_SECTION.TABLE_ACTIONS.PRINT_INVOICE}
    </StyledMenuItem>
    <StyledMenuItem disableRipple>
      {ORDERS_SECTION.TABLE_ACTIONS.CONTACT_US}
    </StyledMenuItem>
  </Menu>
);

const ActionMenuBtn = ({ row, actionMenuBtnHandleClick }) => (
  <IconButton
    sx={{ p: 0 }}
    disableRipple
    aria-label='more'
    onClick={e => actionMenuBtnHandleClick(e, row)}
  >
    <MoreVertIcon />
  </IconButton>
);

const ParcelsTable = ({ rows = [], config }) => {
  const theme = useTheme();
  const isSmallScreen = useBreakpoint('md');
  const overlay = useOverlay();
  const { showError } = useCustomSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbar = useCustomSnackbar();

  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);

  const actionMenuBtnHandleClick = (e, row) => {
    setActionMenuAnchorEl(e.currentTarget);
    setCurrentRow(row);
  };

  const actionMenuBtnHandleClose = () => {
    setActionMenuAnchorEl(null);
  };

  const handleViewParcel = () => {
    if (currentRow?.parcelNumber) {
      navigate(
        `/profile/orders/${normalizeParcelNumber(currentRow.parcelNumber)}`
      );
    }
  };

  const handlePrintInvoice = useCallback(async parcelNumber => {
    if (parcelNumber) {
      try {
        const url = getOrderInvoiceDownloadUrl(parcelNumber, FILE_FORMAT.PDF);
        window.open(url, '_blank');
      } catch (e) {
        snackbar.showError({
          message: e.message || ORDERS_SECTION.ERROR,
        });
      }
    }
  }, []);

  const sendAgainOrder = useCallback(
    async parcelCode => {
      try {
        overlay.show();

        const { basketItemId } = await dispatch(
          BasketActions.duplicateBasketItem(parcelCode)
        ).unwrap();

        navigate(`/order?basketItemId=${basketItemId}`);
      } catch (err) {
        showError({
          message: ORDERS_SECTION.NOTIFICATIONS.DUPLICATE_ORDER_ERROR,
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch, navigate, overlay, showError]
  );

  return (
    <>
      {!rows.length ? (
        <Box
          sx={{
            textAlign: 'center',
            mt: 1,
            color: theme.palette.text.secondary,
            fontSize: '12px',
          }}
        >
          {ORDERS_SECTION.NO_ORDERS_ADDED}
        </Box>
      ) : (
        <Box>
          <Table aria-label='parcels table' size='small'>
            <TableHead>
              <TableRow>
                {(isSmallScreen
                  ? config.columns.mobile
                  : config.columns.desktop
                ).map(({ heading }) => (
                  <StyledTableCell
                    key={heading}
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: '12px',
                    }}
                  >
                    {heading}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow
                  key={row.parcelNumber}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    verticalAlign: 'top',
                  }}
                >
                  {isSmallScreen ? (
                    <StyledTableCell>
                      {config.columns.mobile[0].content.map(
                        ({ path, getter }, index) => (
                          <StyledBox
                            key={path}
                            sx={{
                              color:
                                index === 0
                                  ? theme.palette.text.primary
                                  : theme.palette.text.secondary,
                            }}
                            data-testid={`${rowIndex}_parcels.${path}_${index}`}
                          >
                            {getter ? getter(row, path) : row[path]}
                          </StyledBox>
                        )
                      )}
                    </StyledTableCell>
                  ) : (
                    <>
                      {config.columns.desktop
                        .filter(({ heading }) => !!heading)
                        .map(({ path, getter }, columnIndex) => (
                          <StyledTableCell
                            key={path}
                            data-testid={`${rowIndex}_parcels.${path}_${columnIndex}`}
                          >
                            {getter ? getter(row, path) : row[path]}
                          </StyledTableCell>
                        ))}
                    </>
                  )}
                  <StyledTableCell
                    sx={{ paddingX: 1, width: '40px' }}
                    align='right'
                    data-testid={`${rowIndex}_parcels.actionMenuBtn_${
                      isSmallScreen
                        ? config.columns.mobile.length - 1
                        : config.columns.desktop.length - 1
                    }`}
                  >
                    <ActionMenuBtn
                      row={row}
                      actionMenuBtnHandleClick={actionMenuBtnHandleClick}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <ActionMenu
            actionMenuAnchorEl={actionMenuAnchorEl}
            actionMenuBtnHandleClose={actionMenuBtnHandleClose}
            handleViewParcel={handleViewParcel}
            handleSendAgainOrder={() => sendAgainOrder(currentRow?.parcelCode)}
            handlePrintInvoice={() =>
              handlePrintInvoice(currentRow?.parcelNumber)
            }
          />
        </Box>
      )}
    </>
  );
};

ParcelsTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      parcelNumber: PropTypes.string,
      recipient: PropTypes.string,
      postcode: PropTypes.string,
      description: PropTypes.string,
      ordered: PropTypes.string,
      collected: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  config: PropTypes.object.isRequired,
};

export default ParcelsTable;
